import React, { useRef, useEffect } from "react";
import * as d3 from "d3";

export const PieChart = ({ data }) => {
  const chartRef = useRef();

  useEffect(() => {
    drawChart();
  }, [data]);

  const drawChart = () => {
    const width = 325;
    const height = 325;
    const radius = Math.min(width, height) / 2;

    d3.select(chartRef.current).selectAll("*").remove();

    const isAllZero = data.every((item) => item.value === 0);

    const svg = d3
      .select(chartRef.current)
      .append("svg")
      .attr("width", width)
      .attr("height", height)
      .append("g")
      .attr("transform", `translate(${width / 2}, ${height / 2})`);

    if (isAllZero) {
      const arc = d3.arc().innerRadius(radius - 55).outerRadius(radius);
      const pie = d3.pie().value(() => 1);

      svg
        .selectAll(".arc")
        .data(pie([{ name: "No Data", value: 1 }]))
        .enter()
        .append("path")
        .attr("d", arc)
        .attr("fill", "#e0e0e0");

      svg
        .append("text")
        .attr("text-anchor", "middle")
        .attr("font-size", "24px")
        .attr("font-weight", "bold")
        .text("0");

      svg
        .append("text")
        .attr("text-anchor", "middle")
        .attr("font-size", "12px")
        .attr("y", 20)
        .text("Total Clicks");

      return;
    }

    const svgNode = d3.select(chartRef.current).node();
    const tooltip = d3
      .select(svgNode.parentNode)
      .append("div")
      .style("cursor", "default")
      .style("opacity", 0)
      .attr("class", "tooltip")
      .style("background-color", "white")
      .style("position", "absolute")
      .style("border", "solid #F2F2F2")
      .style("border-width", "1px")
      .style("border-radius", "4px")
      .style("padding", "10px")
      .style("box-shadow", "1px 1px 1px #F2F2F2");

    const pie = d3.pie().value((d) => d.value);
    const arc = d3.arc().innerRadius(radius - 55).outerRadius(radius);

    const arcs = svg
      .selectAll(".arc")
      .data(pie(data))
      .enter()
      .append("g")
      .attr("class", "arc");

    arcs
      .append("path")
      .attr("d", arc)
      .attr("fill", (d) => d.data.color);

    arcs
      .append("path") 
      .attr("d", arc)
      .attr("fill", "transparent")
      .attr("stroke", "none")
      .style("pointer-events", "all")
      .on("mouseover", (event, d) => {
        tooltip
          .style("opacity", 1)
          .html(`
              <div style="display: flex; align-items: center; margin-bottom: 5px; font-weight: bold;">
                <div
                  style="width: 10px; height: 10px; background-color: ${d.data.color}; border-radius: 2px; margin-right: 5px;">
                </div>
                <div style="flex: 1;">${d.data.name}</div>
              </div>
              <div style="display: flex; justify-content: space-between; margin-bottom: 5px;">
                <div style="color: #555;">Direct clicks: </div>
                <div style="color: black; font-weight: bold;">${d.data.value.toLocaleString()}</div>
              </div>
              <div style="display: flex; justify-content: space-between;">
                <div style="color: #555;">Percentage: </div>
                <div style="color: black; font-weight: bold;">${((d.data.value / data.reduce((acc, item) => acc + item.value, 0)) * 100).toFixed(2)}%</div>
              </div>
            `)
          .style("left", `${event.pageX + 20}px`)
          .style("top", `${event.pageY + 50}px`);
      })
      .on("mousemove", (event) => {
        tooltip
          .style("left", `${event.pageX + 20}px`)
          .style("top", `${event.pageY - 10}px`);
      })
      .on("mouseout", () => {
        tooltip.style("opacity", 0);
      });

    const totalValue = data.reduce((acc, item) => acc + item.value, 0);
    svg
      .append("text")
      .attr("text-anchor", "middle")
      .attr("font-size", "24px")
      .attr("font-weight", "bold")
      .text(totalValue.toLocaleString());

    svg
      .append("text")
      .attr("text-anchor", "middle")
      .attr("font-size", "12px")
      .attr("y", 20)
      .text("Total Clicks");
  };

  return <div ref={chartRef}></div>;
};
