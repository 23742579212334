import React from "react";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import {searchInputStyle} from "./SearchInputStyle";
import PropTypes from "prop-types";
import {Tooltip} from "@mui/material";

export const SearchInput = ({placeholderText, action, sxOverride}) => {
	return (
		<Paper
			component={"form"}
			id={"searchInputForm"}
			sx={sxOverride?.paper ? sxOverride.paper : searchInputStyle.paper}
			elevation={0}
			variant={"outlined"}
			onSubmit={action}
		>
			<InputBase
				sx={sxOverride?.input ? sxOverride.input : searchInputStyle.input}
				placeholder={placeholderText}
				size={"medium"}
				required
			/>
			<Tooltip title={"Search by Content ID or Media ID"}>
				<IconButton type={"submit"} aria-label={"search"} onClick={action}
				            sx={sxOverride?.button ? sxOverride.button : searchInputStyle.button}>
					<SearchIcon/>
				</IconButton>
			</Tooltip>
		</Paper>
	)
}

SearchInput.propTypes = {
	sxOverride: PropTypes.object,
	action: PropTypes.func.isRequired,
	placeholderText: PropTypes.string,
}
