import React from "react";
import {DataGridPremium,} from "@mui/x-data-grid-premium";
import { standaloneVideoInsightsTableColumns } from "./StandaloneVideoInsightsTableHelper";
import { standaloneVideoInsightsTableStyle } from "./StandaloneVideoInsightsTableStyle";
import { CustomNoRowsOverlay } from "../CustomNoRowsOverlay/CustomNoRowsOverlay";

export const StandaloneVideoInsightsTable = ({tableData}) => {
	return (
		<DataGridPremium
			sortingOrder={["asc", "desc"]}
			sx={standaloneVideoInsightsTableStyle.dataGrid}
			columns={standaloneVideoInsightsTableColumns}
			components={{noRowsOverlay: CustomNoRowsOverlay}}
			rows={tableData}
			columnHeaderHeight={38}
			rowHeight={80}
			autoHeight={true}
            hideFooter={true}
		/>
	);
}