import {ListItemButton, ListItemIcon, ListItemText} from "@mui/material";
import {Link as RouterLink} from "react-router-dom";
import {defaultDrawerListItemButtonStyle} from "../../../../assets/styles/styles";
import React from "react";

export const StandardDrawerListItemButton = ({item, disabled}) => {
	return (
		<ListItemButton
			component={RouterLink}
			to={item.path}
			sx={(theme) => ({...defaultDrawerListItemButtonStyle,})}
			disabled={disabled}
		>
			<ListItemIcon>{item.icon}</ListItemIcon>
			<ListItemText primary={item.name}/>
		</ListItemButton>)
}