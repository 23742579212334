import {getFormattedDate} from "../../../../utils/formatters";
import apiCall from "../../../../utils/apiCall";
import {getTime} from "../../../../utils/utils";

export const alertTitle = "Sorry! We were not able to find an exact match."
export const alertMessages = [{text: "Check that the Content ID or Media ID has been entered in the correct format."}, {text: "Search results only reflect the date range selected. Consider entering a different date range."}]
export const alertSeverity = "warning"

export const searchPlaceholder = "Search by Content ID or Media ID"

export const fetchContentId = (contentId) => {
	const date = getFormattedDate(new Date())

	const query = `
                query contentDetail($startDate: String!, $endDate: String!, $contentId: String!, ) {
                    contentDetail(startDate: $startDate, endDate: $endDate, contentId: $contentId) {
                        contentType
						contentSubType
                        publishTime
                    }
                }
            `;

	const variables = {
		startDate: date,
		endDate: date,
		contentId: contentId
	};

	return apiCall(query, variables)
}

export const validateContentId = (response, dateRange, setMinDate, setDateRange, setValidation) => {
	const alert = response === undefined ? true : response["contentDetail"]["contentSubType"] === null
	if (!alert) {
		const publishedDate = new Date(response["contentDetail"]["publishTime"])
		setMinDate(publishedDate)
		if (!dateRange.validated || getTime(publishedDate, true) >= getTime(new Date(dateRange.validated[1]), true)){
			setDateRange((prevState) => ({
				...prevState,
				selected: [publishedDate, new Date()], 
				validated: [publishedDate, new Date()]
			}))
		} else if (getTime(publishedDate, true) >= getTime(new Date(dateRange.validated[0]), true)){
			setDateRange((prevState) => ({
				...prevState,
				selected: [publishedDate, prevState.selected[1]], 
				validated: [publishedDate,  prevState.validated[1]]
			}))
		} 
		setValidation((prevState)=>({contentType:response["contentDetail"]["contentType"], isValidating: false, error: false}))

	} else {
		setDateRange((prevState) => ({
			...prevState,
			selected: [new Date(),  new Date()], 
			validated: [new Date(), new Date()]
		}))
		setValidation((prevState)=>({...prevState, isValidating: false, error: true}))
	}

}

export const beforeAfterTableColumnsVisibilityModel = {
	percentage: true,
	contentInfo: true,
	contentType: false,
	contentCategories: false,
	totalPageviewStreamed: true,
	totalReadWatched: true,
	readWatchedPercentage: false,
	avgTimeSpent: false
}