import React, {useEffect, useState} from "react";
import {StateCard} from "../StateCard/StateCard";
import {SmallTableSkeleton} from "../../Loading/Skeleton/SmallTableSkeleton/SmallTableSkeleton";
import {PaperGrid} from "../../Foundational/PaperGrid/PaperGrid";
import {LineupTable} from "../../Tables/LineupTable/LineupTable";
import {Grid, Typography} from "@mui/material";
import {lineupCardStyle} from "./LineupCardStyle";
import {filterLineup, lineupApi} from "./LineupCardHelper";
import {Dropdown} from "../../Foundational/Dropdown/Dropdown";
import PropTypes from "prop-types";

const title = "Featured in which lineups?"
const emptyMessage = "Lineup data currently unavailable"


export const LineupCard = ({dateRange, contentId, setHasError, setEmpty}) => {

	const [dataInfo, setDataInfo] = useState({loading: true, error:false, data: []})
	const [filterInfo, setFilterInfo] = useState(
		{filteredData: dataInfo.data, chosenLineup: {id: "", name: ""}, filters: []})

	const handleLineup = (_event, element) => {
		let currentLineupObject = {id: element.props.value, name: element.props.children[1]}
		setFilterInfo((prevState) => ({...prevState, chosenLineup: currentLineupObject}))
	}

	useEffect(() => filterLineup(filterInfo, setFilterInfo, dataInfo),
		[dateRange, contentId, dataInfo.data, filterInfo.chosenLineup])

	useEffect(() => lineupApi(dateRange, contentId, setDataInfo, setFilterInfo, setHasError, setEmpty), [dateRange, contentId]);

	if (dataInfo.loading || dataInfo.error) {
		return (
			<StateCard loading={dataInfo.loading} error={dataInfo.error} component={<SmallTableSkeleton/>}
			           message={emptyMessage}/>
		)
	}

	return (
		<PaperGrid>
			<Grid container item direction={"column"} spacing={2}>
				<Grid container item direction={"row"} justifyContent={"space-between"} spacing={2} wrap={"nowrap"}>
					<Grid item>
						<Typography sx={lineupCardStyle.lineupTitle} variant={"h6"}>
							{title}
						</Typography>
					</Grid>

					<Grid item sx={lineupCardStyle.lineupDropdownItem}>
						<Dropdown selectItems={filterInfo.filters} inputLabel={"Lineups"}
						          inputId={filterInfo.chosenLineup.id}
						          onChange={handleLineup}/>
					</Grid>
				</Grid>

				<Grid item>
					<LineupTable lineupData={filterInfo.filteredData}/>
				</Grid>
			</Grid>
		</PaperGrid>
	)
}

LineupCard.propTypes = {
	dateRange: PropTypes.array.isRequired,
	contentId: PropTypes.string.isRequired,
	setEmpty:PropTypes.func.isRequired,
	setHasError:PropTypes.func.isRequired,
}