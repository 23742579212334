import { amountFormatter } from "../../../../utils/formatters"
import { renderZoneCellStyle } from "./RenderZoneCellStyle"
import { Grid, Typography, Box } from "@mui/material";
import React from "react"

export const RenderZoneCell = (params) => {
    const zone = params.row.zone
    const averagePageViews = amountFormatter(params.row.averagePageViews)

    return(
    <Grid container direction={"row"} wrap={"nowrap"} justifyContent={"left"}>
        { zone !== "middle" &&
        <Grid item>
            <Box sx={zone === "top" ? renderZoneCellStyle.zoneBoxTop : renderZoneCellStyle.zoneBoxBottom}>
                <Typography sx={renderZoneCellStyle.zoneName}>
                    {zone.charAt(0).toUpperCase() + zone.slice(1)}
                </Typography>
            </Box>
        </Grid>
        }
        <Grid item>
            <Typography sx={renderZoneCellStyle.averagePageViews}>
                {averagePageViews}
            </Typography>
        </Grid>
    </Grid>
)}