import { getFormattedDate } from "../../../utils/formatters";
import apiCall from "../../../utils/apiCall";
export const title = "Embedded Videos"
export const emptyMessage = "Embedded Video data currently unavailable"

export const videoEmbedsInArticleApi = (contentId, dateRange, setDataInfo) => {
	setDataInfo((prevData) => ({...prevData, loading: true, error:false}))

	const startDate = getFormattedDate(dateRange[0])
	const endDate = getFormattedDate(dateRange[1])
	const query = `
            query videoEmbedsInArticle($startDate: String!, $endDate: String!, $contentId: String!) {
                videoEmbedsInArticle(startDate: $startDate, endDate: $endDate, contentId: $contentId) {
                    contentId
                    contentHeadline
                    publishTime
                    contentSubtype
                }
            }
        `;

	const queryVariable = {
		startDate: startDate,
		endDate: endDate,
		contentId: contentId
	};

	apiCall(query, queryVariable)
		.then(response => {
			const {videoEmbedsInArticle: videoEmbedsInArticleData} = response;

            setDataInfo({
                data: videoEmbedsInArticleData,
                loading: false, 
                error:false
            })
			
		}).catch(error => {
            console.log({videoEmbedsInArticleError: error})
            setDataInfo({
                data: [],
                loading: false, 
                error:true
            })
	});

}