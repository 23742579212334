import React from "react";
import {Grid, Typography, Box} from "@mui/material";
import { tagTitleStyle } from './TagTitleStyle';


export const TagTitle = ({department}) => {
	return (
		<Grid container item direction={"column"} spacing={2}>
				<Grid item>
					<Typography sx={tagTitleStyle.headline}>
                        Subject Distribution by <Box fontWeight={800} display={"inline"}>{department.name}</Box>
					</Typography>
				</Grid>
        </Grid>
	)
}