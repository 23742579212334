import dayjs from "dayjs";
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);

export const shortcutsItems = [
	{
		label: "Today",
		getValue: () => {
			const today = dayjs();
			return [today.toDate(), today.toDate()];
		},
	},
	{
		label: "Last Week",
		getValue: () => {
			const today = dayjs();
			const prevWeek = today.startOf("week").subtract(1, "day")
			return [prevWeek.startOf("week").toDate(), prevWeek.toDate()];
		},
	},
	{
		label: "Last Month",
		getValue: () => {
			const today = dayjs();
			const endOfLastMonth = today.startOf("month").subtract(1, "day");
			return [endOfLastMonth.startOf("month").toDate(), endOfLastMonth.toDate()];
		},
	},
	{
		label: "Last 3 Months",
		getValue: () => {
			const today = dayjs();
			const endOfLastMonth = today.startOf("month").subtract(1, "day");
			const endOfThirdLastMonth = endOfLastMonth.subtract(2, "month")
			return [endOfThirdLastMonth.startOf("month").toDate(), endOfLastMonth.toDate()];
		},
	},
	{
		label: "Last 6 Months",
		getValue: () => {
			const today = dayjs();
			const endOfLastMonth = today.startOf("month").subtract(1, "day");
			const endOfSixthMonth = endOfLastMonth.subtract(5, "month")
			return [endOfSixthMonth.startOf("month").toDate(), endOfLastMonth.toDate()];
		},
	},
	{
		label: "Last 12 Months",
		getValue: () => {
			const today = dayjs();
			const endOfLastMonth = today.startOf("month").subtract(1, "day");
			const endOfTwelfthMonth = endOfLastMonth.subtract(11, "month")
			return [endOfTwelfthMonth.startOf("month").toDate(), endOfLastMonth.toDate()];
		},
	},
];

export const onChangeAction = (setDateRange) => {		
	return ((newDateValue) => {
		setDateRange((prevDateRange) => ({
			...prevDateRange,
			selected: newDateValue
		}))
	})
}

export const onClickHandler = (setOpen) => {
		setOpen((prev) => !prev)
}

export const onKeyDownHandler = (event, setOpen) => {
	if (event.key === "Enter"){
		setOpen((prev) => !prev)
	}
}

export const displayHelperText = (startDate, endDate) => {
    const start = dayjs(startDate);
    const end = dayjs(endDate);
    const today = dayjs();
    const duration = end.diff(start, "day");

    if (duration === 0 && start.isSame(today, "day")) {
        return "Today";
    }

    if (duration > 2 && start.isSameOrAfter(today.startOf("week")) && end.isSameOrBefore(today.endOf("week"))) {
        return "This Week";
    }

    const startOfLastWeek = today.subtract(1, "week").startOf("week");
    if (duration < 8 && start.isBefore(today.startOf("week")) && end.isAfter(startOfLastWeek) && start.month() === end.month()) {
        return "Last Week";
    }

    if (start.isSame(today.startOf("month"), 'day') && end.isSameOrBefore(today.endOf("month"))) {
        return "This Month";
    }

    const daysInLastMonth = today.subtract(1, "month").daysInMonth();
    const startOfLastMonth = today.subtract(1, "month").startOf("month");
    const endOfLastMonth = startOfLastMonth.endOf("month");
    if (((start.isSameOrAfter(startOfLastMonth) && end.isSameOrBefore(endOfLastMonth)) || (start.isBefore(endOfLastMonth) && duration <= daysInLastMonth))) {
        return "Last Month";
    }

    return "";
};
