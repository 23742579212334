export const departmentDropdownStyle = {
    columnButton : {typography:"text.body2", textTransform: "none", fontWeight:400,
                    color:"common.black",
                    justifyContent: "flex-start",
                    width:"100%",
                    "&: hover": {
                        color: "primaryColour.chip",
                        backgroundColor: "primaryColour.dropdown",
                    }},
    labelButton: {typography:"text.subtitle2", fontWeight:600, textTransform: "none", color:"common.black",
        "&: hover":{backgroundColor:"transparent", color:"common.black"}},
    paperProps:{borderRadius:"30px"},
    grid:{
        padding: {padding:6},
        columnWidth: {minWidth:"14rem"},
    },
    columnLabel:{typography:"text.body2", fontWeight:700, paddingBottom:2}

}