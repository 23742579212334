export const mediaKPIGroupStyle = {
    grid: {height: "120px", width: "100%", maxWidth:"200px", paddingY:1,  backgroundColor:"grey.100", borderRadius:"9px"},
    secondaryGrid: {height: "100%", width: "100%", paddingX:3},
    divider:{
        marginBottom:3,
        marginX:1
    },
    title:{
        typography:"text.subtitle2",
        paddingLeft:1
    },
    secondaryTitle:{
        typography:"text.subtitle2",
    },
    spacer:{
        height:"1.25rem"
    },
    primaryValue:{
        typography: "numeric.h5",
        fontWeight: 400,
        paddingLeft:1
    },
    secondaryValue: {
        typography: "text.subtitle2", 
    },
}