import {getFormattedDate} from "../../../utils/formatters";
import apiCall from "../../../utils/apiCall";


export const lineupApi = (dateRange, contentId, setDataInfo, setFilterInfo, setHasError, setEmpty) => {

	setDataInfo((prevState) => ({...prevState, loading: true, error:false}))

	let startDate, endDate, query;

	startDate = getFormattedDate(dateRange[0])
	endDate = getFormattedDate(dateRange[1])

	query = `
            query lineupLifespan($startDate: String!, $endDate: String!, $contentId: String!) {
                lineupLifespan(startDate: $startDate, endDate: $endDate, contentId: $contentId) {
                    metadata {
                        lineups {
                            id
                            name
                        }
                        contentId
                        startDate
                        endDate
                        metrics
                    }
                    data {
                        id
                        lineup
                        tileBucket
                        lifeSpan
                        lifeSpanSec
                        startTmstmp
                        endTmstmp
                        isAlive
                    }
                }
            }
        `;

	const variables = {startDate: startDate, endDate: endDate, contentId: contentId};

	apiCall(query, variables)
		.then(response => {
			const {lineupLifespan: lineupLifespanData} = response;

			setDataInfo({
				data: lineupLifespanData.data,
				loading: false,
				error:false
			})

			setFilterInfo({
				filteredData: lineupLifespanData.data,
				chosenLineup: lineupLifespanData.metadata.lineups[0],
				filters: lineupLifespanData.metadata.lineups
			})
			if (lineupLifespanData.data.length === 0){
				setEmpty(true)
			}
		})
		.catch(error => {
			console.log({lineupLifespanError: error});
			setDataInfo((prevState) => ({...prevState, loading: false, error:true}))
			setHasError(true)
		});
}

export const filterLineup = (filterInfo, setFilterInfo, dataInfo) => {
	let newFilteredData = filterInfo.chosenLineup.name === "All Lineups" ? dataInfo.data :
		dataInfo.data.filter(data => data.lineup === filterInfo.chosenLineup.name)

	setFilterInfo((prevState) => ({...prevState, filteredData: newFilteredData}))
}