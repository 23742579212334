import {Box, Chip, Divider, List, ListItem} from "@mui/material";
import React from "react";
import {DateRangeShortcutsStyle} from "./DateRangeShortcutsStyle";
import {shortcutFilter} from "./DateRangeShortcutsHelper"
import PropTypes from "prop-types";

export const DateRangeShortcuts = ({items, onChange, isValid}) => {
	if (items == null || items.length === 0) {
		return null;
	}

	const resolvedItems = items.map(shortcutFilter(isValid, onChange));

	return (
		<Box
			sx={DateRangeShortcutsStyle.box}
		>
			<List
				dense
				sx={DateRangeShortcutsStyle.list}
			>
				{resolvedItems.map((item) => {
					return (
						<ListItem key={item.label}>
							<Chip sx={DateRangeShortcutsStyle.chip} {...item} />
						</ListItem>
					);
				})}
			</List>
			<Divider/>
		</Box>
	);
}

DateRangeShortcuts.propTypes = {
	items: PropTypes.array.isRequired,
	onChange: PropTypes.func.isRequired,
	isValid: PropTypes.func.isRequired,
}
