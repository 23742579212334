import React from "react"
import {Grid, Skeleton} from "@mui/material";

export const ContentTagSkeleton = () => {
	return (
		<Grid container item direction={"row"} spacing={1} wrap={"nowrap"}>
			<Grid item xs={4}>
				<Skeleton width={"50%"}/>
			</Grid>
			<Grid item xs={8}>
				<Skeleton width={"80%"}/>
			</Grid>
		</Grid>
	)
}