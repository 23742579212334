import apiCall from "../../../utils/apiCall";
import { getFormattedDate } from "../../../utils/formatters";
export const emptyMessage = "Standalone (player) data currently unavailable"
export const title = "Where did the clicks come from for standalone? (player)"
export const dataBlock = [
    { name: "Line-up", value: 0, color: "#1B42A8" },
    { name: "Swimlane", value: 0, color: "#0070E0" },
    { name: "Carousel", value: 0, color: "#3E91F7" },
    { name: "Recommended", value: 0, color: "#6FB1F9" },
    { name: "Others", value: 0, color: "#C0DCFC" },
]
export const standaloneClicksOriginApi = (dateRange, contentId, setDataInfo) => {
    setDataInfo((prevState) => ({ ...prevState, loading: true, error:false }));

    const startDate = getFormattedDate(dateRange[0])
	const endDate = getFormattedDate(dateRange[1])
	const query = `
        query standaloneVideoClicksOrigin($startDate: String!, $endDate: String!, $contentId: String!) {
            standaloneVideoClicksOrigin(startDate: $startDate, endDate: $endDate, contentId: $contentId) {
                    origin
                    totalClicks
                    percentage   
                }
            }`;
    
    const queryVariable = {
        startDate: startDate,
        endDate: endDate,
        contentId: contentId
    };

    apiCall(query, queryVariable)
    .then(response => {
        const {standaloneVideoClicksOrigin
            : data} = response;

        if (!data || data.length === 0) {
            throw new Error(`Standalone clicks origin query returned an empty list`);
        }
    
        const result = data.reduce((acc, row) => {
            if (row.origin && row.totalClicks !== undefined) {
                acc[row.origin] = row.totalClicks;
            }
            return acc;
        }, {});

        return result;
    })
    .then(result => {

        const data = dataBlock.map((row)=>{
            let name = row.name
            return {...row, value: result[name] ? result[name] : 0 }
        })

        setDataInfo({
            data: data,
            loading: false, 
            error:false
        })
        
    }).catch(error => {
        console.log(error)
        setDataInfo({
            data: dataBlock,
            loading: false, 
            error:true
        })
});
};

