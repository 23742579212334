import { Tooltip } from "@mui/material";
import apiCall from "../../../../utils/apiCall";
import {getFormattedDate} from "../../../../utils/formatters";
import {getDepartments} from "../../../../utils/utils";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import React from "react"
export const emptyMessage = "Tag percentile data currently unavailable"

export const kpis = [
	{
		title: "Range of top performing tags",
		accessor: "topPerformingTags",
		duration: 1,
		units: null,
		tooltip: <Tooltip title={"Tags that fall into the top 25% of the Average Page View values"}><InfoOutlinedIcon fontSize={"small"}/></Tooltip>
	},
	{
		title: "Median",
		accessor: "median",
		duration: 1.5,
		units: null,
		tooltip:<Tooltip title={"The middle value of Average Page Views for all tags; an average"}><InfoOutlinedIcon fontSize={"small"}/></Tooltip>
	},
	{
		title: "Range of low performing tags",
		accessor: "lowPerformingTags",
		duration: 2,
		units: null,
		tooltip:<Tooltip title={"Tags that fall into the Bottom 25% of the Average Page View values"}><InfoOutlinedIcon fontSize={"small"}/></Tooltip>
	},
];

export const tagPercentileApi = (dateRange, department, departments, setDataInfo, mediaType, setEmpty, setHasError) => {
	setDataInfo((prevState) => ({...prevState, loading: true, error:false}))

	let startDate, endDate, query;
	startDate = getFormattedDate(dateRange[0])
	endDate = getFormattedDate(dateRange[1])

	query = `
			query tagMainTable($startDate: String!, $endDate: String!, $department: [String!]!, $contentType:[String!]!) {
				tagMainTable(startDate: $startDate, endDate: $endDate, department: $department, contentType: $contentType) {
					percentiles {
						lowest
						twentyFifthPercentile
						median
						seventyFifthPercentile
						highest
					}
				}
			}
		`;
	const queryVariable = {
		startDate: startDate,
		endDate: endDate,
		department: getDepartments(department, departments),
		// section: ["top", "bottom","middle"],
		contentType: [mediaType.id]
	};

	apiCall(query, queryVariable)
		.then(response => {
			const {tagMainTable: {percentiles: data}} = response;
			if (Object.keys(data).length === 0) {
				const empty = {topPerformingTags:[0], median:[0], lowPerformingTags: [0]}
				setEmpty(true)
				setDataInfo({data: empty, loading: false, error:false})
			} else {
				const tagPerformance = {topPerformingTags:[data.seventyFifthPercentile, data.highest], median:[data.median], lowPerformingTags: [data.lowest, data.twentyFifthPercentile]}
				setDataInfo({data: tagPerformance, loading: false, error:false})
			}

		})
		.catch(error => {
			setDataInfo((prevState) => ({...prevState, loading: false, error:true}))
			setHasError(true)
			console.log({tagPercentilesError: error});
		});
}