import React, {useEffect, useState} from 'react';
import {StateCard} from "../StateCard/StateCard";
import {LargeTableSkeleton} from "../../Loading/Skeleton/LargeTableSkeleton/LargeTableSkeleton";
import {emptyMessage, sections, sectionTitle, tagStatsApi, title,} from "./TagStatsCardHelper";
import {PaperGrid} from "../../Foundational/PaperGrid/PaperGrid";
import {Grid, Typography} from "@mui/material";
import {Dropdown} from "../../Foundational/Dropdown/Dropdown";
import PropTypes from "prop-types";
import { TagStatsTable } from '../../Tables/TagStatsTable/TagStatsTable';
import { tagStatsCardStyle } from './TagStatsCardStyle';

export const TagStatsCard = ({dateRange, department, departments, columnVisibilityModel, setColumnVisibilityModel, mediaType, setEmpty, setHasError}) => {
	const [dataInfo, setDataInfo] = useState({loading: true, error: false, data: []})
	const [sectionFilter, setSectionFilter] = useState({id: "All Sections", name: "All Sections"})
	useEffect(() => tagStatsApi(dateRange, department, departments, sectionFilter, mediaType, setDataInfo, setEmpty, setHasError),[dateRange, department, departments, mediaType, sectionFilter])


	if (dataInfo.loading || dataInfo.error) {
		return <StateCard loading={dataInfo.loading} error={dataInfo.error}
		                  component={<LargeTableSkeleton/>}
		                  message={emptyMessage}/>
	}
	return (
		<PaperGrid>
			<Grid container item
			      direction={"column"}
			      spacing={3}>
				<Grid item>
					<Typography  sx={tagStatsCardStyle.title}>
						{title}
					</Typography>
				</Grid>
				<Grid container item direction={"row"} spacing={3}>
					<Grid item>
						<Dropdown
							onChange={(event, element) => {
								setSectionFilter({id: element.props.value, name: element.props.children[1]})
							}}
							inputName={sectionFilter.name}
							inputLabel={sectionTitle}
							inputId={sectionFilter.id}
							selectItems={sections}
						/>
					</Grid>
				</Grid>
				<Grid item sx={tagStatsCardStyle.cellStyle}>
					<TagStatsTable tagStatsData={dataInfo.data} columnVisibilityModel={columnVisibilityModel}
					           department={department} dateRange={dateRange}
					           setColumnVisibilityModel={setColumnVisibilityModel}/>
				</Grid>
			</Grid>
		</PaperGrid>
	)
}

TagStatsCard.propTypes = {
	dateRange: PropTypes.array.isRequired,
	department: PropTypes.shape({
		id: PropTypes.string.isRequired,
		parent: PropTypes.string.isRequired,
		name: PropTypes.string.isRequired
	}).isRequired,
	columnVisibilityModel: PropTypes.object.isRequired,
	setColumnVisibilityModel: PropTypes.func.isRequired,
	mediaType: PropTypes.arrayOf(PropTypes.shape({
		id: PropTypes.string.isRequired,
		name: PropTypes.string.isRequired
	})),
	setEmpty:PropTypes.func.isRequired,
	setHasError:PropTypes.func.isRequired,
}