export const detailPanelMetricsStyle = {
    outerStack: {height: "100%", boxSizing: "border-box"},
    paper: { flex: 1, py:3, px:6, backgroundColor:"white"},
    title: {typography: "text.body1"},
	subtitle: {
        typography: "text.subtitle2",
        fontWeight: 600,
        paddingLeft:0
    },
    icon:{color:"grey.500"},
    paperGrid: {
        minHeight:"275px"
    }
}