import { getFormattedDate } from "../../../utils/formatters"
import apiCall from "../../../utils/apiCall"
export const kpiGroups = [{title: "Landing", accessor: "landing"}, {title: "Player", accessor: "player"}]
export const title = "Standalone Video Insights Breakdown"
export const emptyMessage = "Standalone video insights data currently unavailable"
export const tableTitle = "Top 5 Standalone Page Breakdown"

export const standaloneVideoInsightsApi = (contentId, dateRange, setDataInfo) => {
    setDataInfo((prevState) => ({ ...prevState, loading: true, error: false }));
    const startDate = getFormattedDate(dateRange[0]);
    const endDate = getFormattedDate(dateRange[1]);
    const queryVariable = { startDate: startDate, endDate: endDate, contentId: contentId };
    const query = `
        query standaloneVideoInsights($startDate: String!, $endDate: String!, $contentId: String!) {
        standaloneVideoInsights(startDate: $startDate, endDate: $endDate, contentId: $contentId) {
            kpiData {
                landing {
                        watchedStreams
                        streams
                        watched
                }
                player {
                        watchedStreams
                        streams
                        watched
                }
            }
            tableData {
                id
                page
                playNext
                streams
                watched
            }
        } 
    }`;

    apiCall(query, queryVariable)
    .then(response => {
        const {standaloneVideoInsights: standaloneVideoInsightsData} = response;
        setDataInfo({
            loading: false,
            error:false,
            data: standaloneVideoInsightsData
        })
    })
    .catch(error => {
        console.error("API Error:", error);
        setDataInfo({
            loading: false,
            error: true,
            data: {}
        })
    });
};