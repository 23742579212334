import { getFormattedDate } from "../../../utils/formatters"
import apiCall from "../../../utils/apiCall"
export const title = "Video Performance"
export const message = "Video Performance metrics currently unavailable"
export const kpiGroups = [{title: "Standalone", accessor: "standalone"}, {title: "Embedded", accessor: "embedded"}]
export const videoPerformanceApi = (contentId, dateRange, setDataInfo) => {
        setDataInfo((prevState) => ({...prevState, loading: true, empty: false}))
        const startDate = getFormattedDate(dateRange[0])
        const endDate = getFormattedDate(dateRange[1])
        const queryVariable = {startDate: startDate, endDate: endDate, contentId: contentId};
        const query = `
            query videoPerformance($startDate: String!, $endDate: String!, $contentId: String!) {
                videoPerformance(startDate: $startDate, endDate: $endDate, contentId: $contentId) {
                    standalone {
                        streams
                        watched
                        watchedStreams
                    }
                    embedded {
                        streams
                        watched
                        watchedStreams
                    }
                }
            }
        `;
        apiCall(query, queryVariable)
            .then(response => {
                const {videoPerformance: videoPerformanceData} = response;
                setDataInfo({
                    data: videoPerformanceData,
                    loading: false, 
                    error:false
                })
                
            }).catch(error => {
                console.log({videoPerformanceError:error})
                setDataInfo({
                    data: {},
                    loading: false, 
                    error:true
                })
        });

    }