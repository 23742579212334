import React from "react"
import LogoutIcon from "@mui/icons-material/Logout";
import { Button } from "@mui/material"
import { logOutButtonStyle } from "./LogOutButtonStyle";
import { useAuth } from "../../../firebase/context";
import PropTypes from "prop-types";

export const LogOutButton = ({setAnchor}) => {
    const { signOut } = useAuth();
    return(
        <Button size={"medium"} startIcon={<LogoutIcon/>} 
            onMouseLeave={(event)=>setAnchor(null)} 
            onClick={()=>{signOut()}} 
            sx={logOutButtonStyle.button}> 
            Log out
        </Button>
    )
}

LogOutButton.propTypes = {
	setAnchor: PropTypes.func.isRequired,
}