import {createTheme} from "@mui/material/styles";

export const defaultPalette = {"Text": ["#FF3737", "#01012A"], "other": ["#09D8E8", "#01012A"]}
export const emptyStateSVGPalette = {
	img1: "#aeb8c2",
	img2: "#f5f5f7",
	img3: "#dce0e6",
	img4: "white",
	img5: {
		color: "#f5f5f5",
		opacity: "0.8"
	}
}

export const BG_COLOR = '#fafafa';
export const TEXT_COLOR = '#000000';

export const theme = createTheme({
	palette: {
		primaryColour: {
			brand: "#FF3737",
			chip: "#C4000E",
			dropdown: "#ffbbae"
		},
		secondaryColour: {
			main: "#01012A",
		},
		tertiaryColour: {
			article: ["#FF3737", "#01012A"],
			video: ["#09D8E8", "#01012A"]
		},
		blueInfo:"#347694",
	},
	// Fonts
	typography: {
		text: {
			h5: {
				fontFamily: ["Radio Canada", "sans-serif"].join(","),
				fontSize: "1.375rem",
				fontWeight: 600
			},
			h6: {
				fontFamily: ["Radio Canada", "sans-serif"].join(","),
				fontSize: "1.25rem",
				fontWeight: 600
			},
			subtitle1: {
				fontFamily: ["Radio Canada", "sans-serif"].join(","),
				fontSize: "1rem",
				fontWeight: 600
			},
			subtitle2: {
				fontFamily: ["Radio Canada", "sans-serif"].join(","),
				fontSize: "1rem",
			},
			body1: {
				fontFamily: ["Radio Canada", "sans-serif"].join(","),
				fontSize: ".875rem",
				fontWeight: 600
			},
			body2: {
				fontFamily: ["Radio Canada", "sans-serif"].join(","),
				fontSize: ".875rem",
			},
			chipSmall: {
				fontFamily: ["Radio Canada", "sans-serif"].join(","),
				fontSize: ".8125rem",
			},
			tooltip: {
				fontFamily: ["Radio Canada", "sans-serif"].join(","),
				fontSize: ".625rem",
				fontWeight: 600
			},
		},
		numeric: {
			h5: {
				fontFamily: ["Radio Canada", "monospace"].join(","),
				fontSize: "1.5rem",
			},
			body2: {
				fontFamily: ["Radio Canada", "monospace"].join(","),
				fontSize: ".875rem",
			},
			tooltip: {
				fontFamily: ["Radio Canada", "monospace"].join(","),
				fontSize: ".625rem",
			},
		},
	}
})