import {RenderContentDetail} from "../RenderCell/RenderContentDetail/RenderContentDetail";
import {RenderChips} from "../RenderCell/RenderChips/RenderChips";
import {csvTimeFormatter, getContent, valueFormatter} from "../TablesHelper";
import {gridStringOrNumberComparator} from "@mui/x-data-grid-premium";
import { RenderTimeFormatter } from "../RenderCell/RenderTimeFormatter/RenderTimeFormatter";

const percentageFormatter = (params) => (`${params.value}%`)
const accessor = {
	timestamp: "contentPublishTime",
	title: "contentHeadline",
	type: "contentSubtype",
	contentId: "contentId"
}

export const beforeAfterTableColumns = [
	{
		field: "contentInfo",
		headerName: "Content",
		disableColumnMenu: true,
		flex: 2,
		editable: false,
		valueGetter: getContent.bind(accessor),
		renderCell: RenderContentDetail,
		valueFormatter: ({value}) => (value.title + " - " + value.contentId),
		sortComparator: (v1, v2, param1, param2) => gridStringOrNumberComparator(v1.title, v2.title, param1, param2),
	},
	{
		field: "percentage",
		headerName: "% Interactions",
		disableColumnMenu: true,
		flex: 0.5,
		editable: false,
		valueFormatter: percentageFormatter,
		cellClassName: "numbers",
	},
	{
		field: "contentSubtype",
		headerName: "Content Type",
		disableColumnMenu: true,
		flex: 0.75,
		editable: false,
	},
	{
		field: "contentCategories",
		headerName: "Categories",
		sortable: false,
		disableColumnMenu: true,
		flex: 1,
		editable: false,
		renderCell: RenderChips,
	},
	{
		field: "totalPageviewStreamed",
		headerName: "Pageviews, Streams",
		disableColumnMenu: true,
		flex: 0.75,
		editable: false,
		valueFormatter: valueFormatter,
		cellClassName: "numbers",
	},

	{
		field: "totalReadWatched",
		headerName: "Total Read, Total Watch",
		disableColumnMenu: true,
		flex: 0.75,
		valueFormatter: valueFormatter,
		cellClassName: "numbers",
		editable: false
	},

	{
		field: "readWatchedPercentage",
		headerName: "%Read, %Watch",
		disableColumnMenu: true,
		flex: 0.75,
		editable: false,
		valueFormatter: percentageFormatter,
		cellClassName: "numbers",
	},

		{
			field: "avgTimeSpent",
			headerName: "Average Time Spent",
			flex: 0.75,
			renderCell: RenderTimeFormatter,
			valueFormatter:csvTimeFormatter,
			editable: false,
			disableColumnMenu: true,
		},
	
		{
			field: "totalTimeSpent",
			headerName: "Total Time Spent",
			flex: 0.75,
			renderCell: RenderTimeFormatter,
			valueFormatter:csvTimeFormatter,
			editable: false,
			disableColumnMenu: true,
		},
]