import React, {useEffect, useState} from 'react';
import {PaperGrid} from "../../../../Foundational/PaperGrid/PaperGrid";
import {chartMetricsAPI, emptyMessage, panels} from "./GraphTabsHelper"
import {Box, Grid, Tab, Tabs, Typography} from "@mui/material";
import {TabPanel} from "../TabPanel";
import {StateCard} from "../../../StateCard/StateCard";
import {SmallTableSkeleton} from "../../../../Loading/Skeleton/SmallTableSkeleton/SmallTableSkeleton";
import {graphTabsStyle} from "./GraphTabsStyle";
import PropTypes from "prop-types";
import {graphOverviewStyle} from "../../GraphOverview/GraphOverviewStyle";
import { ChartOverlay } from "../../../../Charts/ChartOverlay/ChartOverlay";

export const GraphTabsCard = ({dateRange, department, departments, setEmpty, setHasError}) => {
	const [tab, setTab] = useState(0)
	const [dataInfo, setDataInfo] = useState({loading: true, error:false, data: {}})

	useEffect(() => {
		chartMetricsAPI(dateRange, department, departments, setDataInfo, setEmpty, setHasError)
	}, [dateRange, department, departments])

	if (dataInfo.loading || dataInfo.error) {
		return (
			<StateCard loading={dataInfo.loading} error={dataInfo.error}
			           component={<SmallTableSkeleton/>} message={emptyMessage}/>
		)
	}
	return (
		<PaperGrid sxPaperOverride={graphTabsStyle.paper}>
			<Grid container item spacing={2} direction={"column"}>
				<Grid item>
					<Tabs
						value={tab}
						key={tab}
						onChange={(event, value) => {
							setTab(value)
						}}
						TabIndicatorProps={{
							sx: graphTabsStyle.tabIndicator
						}}
						centered={true}
					>
						{panels.map((element, index) => (
							<Tab key={index + element.label} label={element.label} disabled={element.disabled}
							     sx={graphTabsStyle.tab}/>
						))}

					</Tabs>
				</Grid>
				{panels.map((element, index) => {
					return (
						<TabPanel value={tab} index={index} key={index + element.label} >
							<PaperGrid sxPaperOverride={graphTabsStyle.paper}>
								<Grid container item direction={"column"}>
									{dataInfo.data[element.accessor].labels &&
									<Grid container item direction={"row"} justifyContent={"flex-end"} spacing={1}
									      sx={graphTabsStyle.labels}>
										{dataInfo.data[element.accessor].labels.map((label, index) => (
											<Grid container item alignItems={"center"} direction={"row"}
											      xs={label === "Average Time Spent" ? 2 : 1}
											      wrap={"nowrap"}
											      key={label + index}>
												<Box component={Grid} style={{
													...graphTabsStyle.kpiLegend,
													...{backgroundColor: element.colourPalette[index]}
												}}
												/>
												<Grid item>
													<Typography sx={graphOverviewStyle.kpiTooltip}>
														{label}
													</Typography>
												</Grid>
											</Grid>
										))}
									</Grid>

									}
									<Grid item sx={graphTabsStyle.grid} justifyContent={"center"}>
										{React.cloneElement(element.chart, {
											data: dataInfo.data[element.accessor].data,
											groups: dataInfo.data[element.accessor].labels,
											colourPalette: element.colourPalette,
											isEmpty: dataInfo.data[element.accessor].empty,
										})}
										{dataInfo.data[element.accessor].empty && <ChartOverlay /> }
									</Grid>
								</Grid>
							</PaperGrid>
						</TabPanel>)
				})}
			</Grid>
		</PaperGrid>
	)
}

GraphTabsCard.propTypes = {
	dateRange: PropTypes.array.isRequired,
	department: PropTypes.shape({
		id: PropTypes.string.isRequired,
		parent: PropTypes.string.isRequired,
		name: PropTypes.string.isRequired
	}).isRequired,
	setEmpty:PropTypes.func.isRequired,
	setHasError:PropTypes.func.isRequired,
}