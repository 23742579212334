import { useEffect } from "react";
import {useLocation} from "react-router-dom";
import {SITE_TITLE} from "./assets/styles/variables";

export const TitleManager = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    if (pathname.startsWith("/DCAD")) {
      const contentId = pathname.split("/")[2];
      document.title = contentId ? `DCAD - ${contentId}` : "DCAD";
    } else if (pathname === "/OCAD") {
      document.title = "OCAD";
    } else if (pathname === "/CCAD") {
      document.title = "CCAD";
    } else {
      document.title = SITE_TITLE;
    }
  }, [pathname]);

  return null;
}