import React, { useEffect, useState, useMemo } from "react";
import { Box, Typography, Chip, Grid } from "@mui/material";
import { PieChart } from "../../Charts/PieChart";
import { PaperGrid } from "../../Foundational/PaperGrid/PaperGrid";
import { StateCard } from "../StateCard/StateCard";
import { SubjectGroupSkeleton } from "../../Loading/Skeleton/SubjectGroupSkeleton/SubjectGroupSkeleton";
import { emptyMessage, standaloneClicksOriginApi, title } from "./StandaloneClicksCardHelper";
import { standaloneClicksCardStyle } from "./StandaloneClicksCardStyle";
import PropTypes from "prop-types";

export const StandaloneClicksCard = ({ dateRange, contentId }) => {
  const [dataInfo, setDataInfo] = useState({ data: [], loading: false, error: true});
  
  const totalClicks = useMemo(() => {
    return dataInfo.data.reduce((acc, item) => acc + item.value, 0);
  }, [dataInfo.data]);

  useEffect(() => standaloneClicksOriginApi(dateRange, contentId, setDataInfo), [dateRange, contentId]);

  if (dataInfo.loading || dataInfo.error) {
    return (
      <StateCard
        loading={dataInfo.loading}
        error={dataInfo.error}
        component={<SubjectGroupSkeleton />}
        message={emptyMessage}
      />
    );
  }

  return (
    <PaperGrid>
      <Grid container direction={"column"} spacing={4}>

        <Grid item align={"left"}>
          <Typography sx={standaloneClicksCardStyle.title}>
            {title}
          </Typography>
        </Grid>

        <Grid container item direction={"row"} spacing={2} wrap={"nowrap"}>
          <Grid
            item
            xs={12}
            md={6}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Box style={standaloneClicksCardStyle.container}>
              <PieChart data={dataInfo.data} />
            </Box>
          </Grid>

          <Grid
            container
            item
            xs={12}
            md={6}
            spacing={2}
            direction={"row"}
            wrap={"wrap"}
          >
            {dataInfo.data.map((item, index) => (
              <Grid
                item
                xs={12} 
                sm={4} 
                md={4} 
                key={index}
              >
                <Grid container direction={"column"} spacing={1}>
                  <Grid container item direction={"row"} spacing={1}>
                    <Grid item>
                      <Box
                        sx={{...standaloneClicksCardStyle.iconBox, backgroundColor:item.color}}
                      />
                    </Grid>
                    <Grid item>
                      <Typography sx={standaloneClicksCardStyle.textBody2}>
                        {item.name}
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid item>
                    <Typography sx={standaloneClicksCardStyle.valueText}>
                      {item.value.toLocaleString()}
                    </Typography>
                  </Grid>

                  <Grid item>
                    <Chip
                      label={`${totalClicks > 0 ? ((item.value / totalClicks) * 100).toFixed(2) : 0}%`}
                      sx={standaloneClicksCardStyle.chip}
                    />
                  </Grid>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </PaperGrid>
  );
};

StandaloneClicksCard.propTypes = {
	contentId: PropTypes.string.isRequired,
	dateRange: PropTypes.array.isRequired,
}