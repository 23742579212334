import React from "react";
import {DataGridPremium} from "@mui/x-data-grid-premium";
import {tablesStyle} from "../TablesStyle";
import { embeddedVideoTableColumns } from "./EmbeddedVideoTableHelper";
import { RenderDCADNavigation } from "../RenderCell/RenderDCADNavigation/RenderDCADNavigation";
import { getNav } from "../TablesHelper";
import { CustomNoRowsOverlay } from "../CustomNoRowsOverlay/CustomNoRowsOverlay"
import { embeddedVideoTableStyle } from "./EmbeddedVideoTableStyle";

export const EmbeddedVideoTable = ({videoEmbedsInArticleData, dateRange}) => {
	const accessor = {
		type: "contentSubtype", contentId: "contentId", dateRange: dateRange
	}
	return (
		<DataGridPremium
			sortingOrder={["asc", "desc"]}
			sx={embeddedVideoTableStyle}
			columns={[{
				field: "Navigation",
				headerName: "",
				flex:1,
				valueGetter: getNav.bind(accessor),
				renderCell: RenderDCADNavigation,
				editable: false,
				disableColumnMenu: true,
				disableExport:true
			  },...embeddedVideoTableColumns]}
			rows={videoEmbedsInArticleData}
			slots={{
				noRowsOverlay: CustomNoRowsOverlay
			}}
			componentsProps={{
				panel: {sx: tablesStyle.toolbar},
			}}
			initialState={{
				sorting: {
					sortModel: [{field: "startTmstmp", sort: "asc"}],
				},
			}}
			columnHeaderHeight={56}
            getRowId={(row) => row.pageViews + "-" + row.contentHeadline}
			rowHeight={72}
			autoHeight={true}
            hideFooter={true}
		/>
	);
}