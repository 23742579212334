import React, {useState} from "react";
import {Box, Divider, Drawer} from "@mui/material";
import {drawerClosedStyle, drawerOpenStyle} from "../../../assets/styles/styles";
import mainLogo from "../../../assets/images/logo-white.svg"
import {NavDrawerList} from "../NavDrawerList/NavDrawerList";
import {navDrawerStyle} from "./NavDrawerStyle";
import {logos, routes, settings} from "./NavDrawerHelper";


export const NavDrawer = ({drawerOpen, setDrawerOpen, userName}) => {

	let [logo, setLogo] = useState(mainLogo)

	return (
		<Drawer variant={"permanent"} anchor={"left"} open={drawerOpen}
				sx={navDrawerStyle(drawerOpen, drawerOpenStyle, drawerClosedStyle)}
				onMouseEnter={() => { setDrawerOpen(true) }}
				onMouseLeave={() => { setDrawerOpen(false) }}
				onFocus={() => setDrawerOpen(true)}
				onBlur={() => setDrawerOpen(false)}
		>
			<Box>
				<Box marginBottom={"26px"} marginX={"8px"}>
					<NavDrawerList items={logos} state={logo} action={setLogo}/>
				</Box>
				<Box marginX={"8px"}>
					<NavDrawerList items={routes}/>
				</Box>
			</Box>
			<Box marginBottom={"16px"} marginX={"8px"}>
				<Divider/>
				<NavDrawerList items={settings(userName)} drawerOpen={drawerOpen}/>
			</Box>
		</Drawer>
	)
}
