import React from "react";
import { useAuth } from "../../../firebase/context";
import { Button, Card, CardActions, CardHeader, Typography, Box } from "@mui/material";
import GoogleIcon from "@mui/icons-material/Google";
import logo from "../../../assets/images/logo-red.svg";
import { loginStyle } from "./LoginStyle";

export const Login = () => {
  const { signInWithGoogle } = useAuth();
  const handleGoogleSignIn = async () => {
    try {
      await signInWithGoogle();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Box sx={loginStyle.box}>
      <Card sx={loginStyle.card}>
        <img src={logo} alt={"MiR Analytics Logo"} style={loginStyle.logo} />
        <CardHeader
          title={
            <Typography sx={loginStyle.cardHeaderTitle}>
              Welcome to MiR Analytics
            </Typography>
          }
          subheader={
            <Typography sx={loginStyle.cardHeaderSubheader}>
              Sign in with your CBC account <br /> to access the content analytics dashboard
            </Typography>
          }
        />
        <CardActions sx={loginStyle.cardActions}>
          <Button
            startIcon={<GoogleIcon />}
            sx={loginStyle.button}
            onClick={handleGoogleSignIn}
          >
            Sign in with Google
          </Button>
        </CardActions>
      </Card>
    </Box>
  );
};
