import React from "react";
import {Container, Grid, Typography, Box} from "@mui/material";
import {FAQGlossaryStyle} from "./FAQGlossaryStyle"
import { GlossaryCard } from "../../../Cards/GlossaryCard/GlossaryCard";
import { glossaryTerms } from "./FAQGlossaryHelper";


export const FAQGlossary = ({}) => {

	return (
		<>
			<Container sx={FAQGlossaryStyle.container}>
					<Grid container direction={"column"} spacing={3}>

					<Grid item sx={FAQGlossaryStyle.glossaryTitleGrid}>
						<Box 
							sx={FAQGlossaryStyle.glossaryTitleBox}
						>
							<Typography sx={FAQGlossaryStyle.glossaryTitle}>
							Glossary Terms List
							</Typography>
						</Box>
						</Grid>
						{glossaryTerms.map((glossaryItem)=>{
							return(<GlossaryCard title={glossaryItem.title} body={glossaryItem.body} tags={glossaryItem.tags} key={glossaryItem.label + glossaryItem.body}/>)
						})}
					</Grid>
			</Container>
		</>
	)
}