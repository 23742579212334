import React, {useEffect, useState} from "react";
import {Box, Container, Grid, Link, Typography} from "@mui/material";
import {ThemeProvider} from "@mui/material/styles"
import {theme} from "../../../assets/styles/themes";
import {NavDrawer} from "../../Navigation/NavDrawer/NavDrawer";
import {LogoProgress} from "../../Loading/Progress/LogoProgress/LogoProgress";
import {alertMessages, alertSeverity, alertTitle, initialiseDepartmentList, storageKey} from "./DashboardHelper";
import {dashboardStyles} from "./DashboardStyles";
import {AlertCard} from "../../Cards/Alerts/AlertCard/AlertCard";
import {SearchInfoCard} from "../../Cards/Info/SearchInfoCard/SearchInfoCard";
import {DateInfoCard} from "../../Cards/Info/DateInfoCard/DateInfoCard";
import {DCADStyle} from "../BodyContent/DCAD/DCADStyle";
import {LicenseInfo} from "@mui/x-license-pro"
import {useAuth} from "../../../firebase/context";
import { DepartmentDropdown } from "../../Foundational/DepartmentDropdown/DepartmentDropdown";

LicenseInfo.setLicenseKey(
	"3d40bcb04e37161754ff5ab211c2b965Tz0xMDAxMjEsRT0xNzYwNzA3MjExMDAwLFM9cHJlbWl1bSxMTT1zdWJzY3JpcHRpb24sUFY9aW5pdGlhbCxLVj0y",
);

const dateRangeLowerBoundLimit = new Date(2021, 5, 11)


export const Dashboard = ({children}) => {
	const {currentUser} = useAuth();
	const chosenDepartment = JSON.parse(sessionStorage.getItem(storageKey))
	const [drawerOpen, setDrawerOpen] = useState(false)
	const [departmentInfo, setDepartmentInfo] = useState({
		loading: true,
		error: false,
		list: [{"id": "", "name": "", "parent": ""}],
		current: chosenDepartment ? chosenDepartment : null
	})

	useEffect(() => initialiseDepartmentList(departmentInfo, setDepartmentInfo), []);

	const handleDepartmentChange = (currentDepartmentObject) => {
		sessionStorage.setItem(storageKey, JSON.stringify(currentDepartmentObject))
		setDepartmentInfo((prevState) => ({
			...prevState,
			current: currentDepartmentObject
		}))
	}
	let departmentFilter = <DepartmentDropdown department={departmentInfo.current} departmentList={departmentInfo.list} handleDepartmentChange={handleDepartmentChange}/>
	return (
		<ThemeProvider theme={theme}>
			{
				departmentInfo.loading ? <LogoProgress overlay={true}/> :
					<Box sx={dashboardStyles.box}>
						<NavDrawer drawerOpen={drawerOpen} setDrawerOpen={setDrawerOpen}
						           userName={currentUser.displayName}/>
						<Container sx={dashboardStyles.contentContainer}>
							{departmentInfo.error ?
								<Grid container direction={"column"} spacing={2}>
									<Grid item>
										<AlertCard title={alertTitle} messages={alertMessages}
										           severity={alertSeverity}/>
									</Grid>
									<SearchInfoCard/>
									<DateInfoCard/>
									<Grid item>
										<Typography textAlign={"left"} sx={DCADStyle.mail}>
											If you have any questions please message CBC’s Machine Intelligence &
											Retention team
											at <Link href="mailto:mir@cbc.ca">mir@cbc.ca</Link>.
										</Typography>
									</Grid>
								</Grid> :
								React.cloneElement(children, {
									drawerOpen: drawerOpen,
									dateRangeLowerBoundLimit: dateRangeLowerBoundLimit,
									departmentFilter: departmentFilter,
									department: departmentInfo.current,
									departments: departmentInfo.list,
								})
							}
						</Container>
					</Box>
			}
		</ThemeProvider>
	)
}
