import apiCall from "../../../utils/apiCall";

export const storageKey = "chosenDepartment"
export const alertTitle = "Sorry! Something went wrong with one of our servers."
export const alertMessages = [{text: "Please contact the MiR team if this issue persists."}]
export const alertSeverity = "error"

export const errorTitle="Unavailable Data"
export const errorMessage="Sorry, there was an issue fetching. Please refesh the page or contact the support team if the issue persists."

export const emptyTitle="No Data Displayed? Here is Why"
export const emptyMessage="No data for your date range? It could be due to no recorded activity or a temporary system delay. Try refreshing the page or select a different date range."

export const initialiseDepartmentList = (departmentInfo, setDepartmentInfo) => {

	const query = `
            query department {
                department {
                    departmentId
                    departmentName
                    departmentParent
                    dropdownHeader
                }
            }
            `;

	apiCall(query).then(response => {
		const {department} = response;
		if (response === undefined) {
			throw new Error("Unable to initialise Department dropdown list")
		}
		const formattedDepartments = department.map(
			entry => ({
				id: entry.departmentId,
				name: entry.departmentName,
				parent: entry.departmentParent,
				header: entry.dropdownHeader
			})
		)

		if (departmentInfo.current && formattedDepartments.find(department => department.id === departmentInfo.current.id)) {
			setDepartmentInfo((prevState) => ({
				...prevState,
				loading: false,
				error: false,
				list: formattedDepartments,
			}))
		} else {
			setDepartmentInfo((prevState) => ({
				...prevState,
				loading: false,
				error: false,
				list: formattedDepartments,
				current: formattedDepartments[0]
			}))
			sessionStorage.setItem(storageKey, JSON.stringify(formattedDepartments[0]))
		}
	})
		.catch(error => {
			setDepartmentInfo((prevState) => ({...prevState, loading: false, error: true}))
			console.log({departmentError: error});
		});
}
