import {RenderContentDetail} from "../RenderCell/RenderContentDetail/RenderContentDetail";
import {getContent, valueFormatter} from "../TablesHelper";
import {gridStringOrNumberComparator} from "@mui/x-data-grid-premium";

const accessor = {
	timestamp: "publishTime",
	title: "contentHeadline",
	contentId: "contentId",
    type: "contentSubtype",
	referrer:"dateRangeCCAD",
}

export const tagTopNColumns = [
    {
		field: "contentInfo",
		headerName: "Content Title",
		disableColumnMenu: true,
		flex: 3,
		editable: false,
		valueGetter: getContent.bind(accessor),
		renderCell: RenderContentDetail,
		valueFormatter: ({value}) => (value.title + " - " + value.contentId),
		sortComparator: (v1, v2, param1, param2) => gridStringOrNumberComparator(v1.title, v2.title, param1, param2),
	},
	{
		field: "pageViews",
		headerName: "Page Views",
		flex: 0.75,
		valueFormatter: valueFormatter,
		cellClassName: "numbers",
		editable: false,
		disableColumnMenu: true,
	},

]