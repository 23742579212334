export const embeddedStoriesTableStyle = {
    border: 0,
    ".MuiDataGrid-columnHeaders": {
      display: "none"
    },
    ".MuiDataGrid-virtualScroller": {
      border: 0,
    },
    ".MuiDataGrid-footerContainer": {
      display: "none",
    },
    "& .MuiDataGrid-cell": {typography: "text.body2"},
    maxWidth: "1022px"
}