import React from "react";
import {PaperGrid} from "../../Foundational/PaperGrid/PaperGrid";
import {Chip, Grid, Typography} from "@mui/material";
import { iconMap } from "./GlossaryCardHelper";
import { GlossaryCardStyle } from "./GlossaryCardStyle";
import PropTypes from "prop-types";

export const GlossaryCard = ({title, body, tags}) => {
	return (
		<PaperGrid elevation={1}
		           sxPaperOverride={GlossaryCardStyle.paperOverride}>
			<Grid container item direction={"column"} spacing={2}>
				<Grid item>
                    <Typography sx={GlossaryCardStyle.title}>
                        {title}
                    </Typography>
				</Grid>
				<Grid item sx={GlossaryCardStyle.body}>
                    {body}
				</Grid>
                {tags?.length >= 1 &&
				<Grid container item direction={"row"} justifyContent={"start"} alignItems={"center"} spacing={1}>
                    <Grid item>
                        <Typography sx={GlossaryCardStyle.tagText}>
                            Appears in:
                        </Typography>
                    </Grid>
					{tags.map((tag)=>{
                        return(
                        <Grid item key={tag.label}>
                            <Chip
                            sx={GlossaryCardStyle.tag}
                            label={tag.label}
                            icon={iconMap[tag.icon]}
                        />
                        </Grid>
                        )
                    })}
				</Grid>}
			</Grid>
		</PaperGrid>
	)
}

GlossaryCard.propTypes = {
    title: PropTypes.string.isRequired,
    body: PropTypes.string.isRequired,
	tags: PropTypes.array.isRequired,
}