export const loginStyle = {
    box: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      minHeight: "100vh",
      background: "linear-gradient(to right, #FFFFFF, #FAE6E6)"
    },
    card: {
      width: "100%",
      maxWidth: "400px",
      textAlign: "center",
      padding: 4,
      backgroundColor: "rgba(255, 255, 255, 0.1)",
      boxShadow:"none",
    },
    logo: {
      width: "180px",
      margin: "0 auto 16px"
    },
    cardHeaderTitle: {
      color: "black",
      fontFamily: ["Radio Canada", "sans-serif"].join(","),
      fontSize: "1.5rem", 
      fontWeight: 700,
      paddingTop: 2,
      paddingBottom:1
    },
    cardHeaderSubheader: {
      color: "black",
      fontFamily: ["Radio Canada", "sans-serif"].join(","),
      fontSize: ".875rem",  
      fontWeight: 400,
      paddingY: 1,
    },
    cardActions: {
      display: "flex",
      justifyContent: "center",
      paddingTop: 1
    },
    button: {
      width: "100%",
      maxWidth: "300px",
      backgroundColor: "#FF3737",
      backgroundColor: "#ffffff",
      color: "#FF3737",
      border: "2px solid #FF3737",
      borderRadius: "10px",
      "&:hover": {
        backgroundColor: "#FF3737",
        color: "#ffffff",
      }
    }
  };