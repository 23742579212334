import React, {useEffect, useState} from "react"
import { articlesWithVideoEmbedApi, emptyMessage, title } from "./EmbeddedStoryCardHelper"
import { embeddedStoryCardStyle } from "./EmbeddedStoryCardStyle"
import { PaperGrid } from "../../Foundational/PaperGrid/PaperGrid"
import { StateCard } from "../StateCard/StateCard"
import { SmallTableSkeleton } from "../../Loading/Skeleton/SmallTableSkeleton/SmallTableSkeleton"
import { Grid, Typography } from "@mui/material"
import { EmbeddedStoriesTable } from "../../Tables/EmbeddedStoriesTable/EmbeddedStoriesTable"
import PropTypes from "prop-types";

export const EmbeddedStoryCard = ({dateRange, contentId}) => {

	const [dataInfo, setDataInfo] = useState({loading: false, error:false,  data: []})
	  useEffect(() => articlesWithVideoEmbedApi(contentId, dateRange, setDataInfo), [dateRange, contentId]);

	if (dataInfo.loading || dataInfo.error) {
		return (
			<StateCard loading={dataInfo.loading} error={dataInfo.error}
			           component={<SmallTableSkeleton/>} message={emptyMessage}/>
		)
	}

	return (
		<PaperGrid>
			<Grid container item direction="column" spacing={2}>
				<Grid item>
					<Typography sx={embeddedStoryCardStyle.title} variant={"h6"}>
						{title}
					</Typography>
				</Grid>
				<Grid item>
					<EmbeddedStoriesTable articlesWithVideoEmbedData={dataInfo.data} />
				</Grid>
			</Grid>
		</PaperGrid>
	)
}

EmbeddedStoryCard.propTypes = {
	dateRange: PropTypes.arrayOf(PropTypes.shape({
		startDate: PropTypes.instanceOf(Date).isRequired,
		endDate: PropTypes.instanceOf(Date).isRequired
	})).isRequired,
	contentId: PropTypes.string.isRequired
}