import {drawerWidthClosed, drawerWidthOpen} from "../../assets/styles/styles";

export const headerStyle = {
	container: {
		minWidth: 275,
		backgroundColor: "grey.50",
		padding: (2, 1, 0, 1),
		borderRadius:"20px"
	},
	header: {
		zIndex: (theme) => (theme.zIndex.drawer) - 1,
		backgroundColor: "grey.50",
		boxShadow: (theme) => (theme.shadows[0]),
		marginLeft: drawerWidthClosed,
		width: `calc(100% - ${drawerWidthClosed}px)`,
		transition: (theme) => (theme.transitions.create(['width', 'margin'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		})),
		minHeight: 75,
		paddingTop: 0.75,
	},
	shift: {
		marginLeft: drawerWidthOpen,
		width: `calc(100% - ${drawerWidthOpen}px)`,
	},
	searchGrid: {
		minWidth: "150px"
	}
}
