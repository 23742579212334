import React from "react";
import { Alert, AlertTitle, IconButton, Collapse , Grid} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { NotificationStyle } from "./NotificationStyle";
import PropTypes from "prop-types";

export const NotificationCard = ({ title, message, severity, open, handleClose }) => {
  return (
    <Grid item>
      <Collapse in={open}>
        <Alert
          severity={severity}
          sx={NotificationStyle}
          action={
            <IconButton
              aria-label={"close"}
              color={"inherit"}
              size={"small"}
              onClick={handleClose}
            >
              <CloseIcon fontSize={"inherit"} />
            </IconButton>
          }
        >
          <AlertTitle>{title}</AlertTitle>
          {message}
        </Alert>
      </Collapse>
    </Grid>
  );
};

NotificationCard.propTypes = {
	title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  severity: PropTypes.string.isRequired,
	open: PropTypes.bool.isRequired,
	handleClose: PropTypes.func.isRequired,
}