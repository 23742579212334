import React from "react";
import {PaperGrid} from "../../../Foundational/PaperGrid/PaperGrid";
import articleImage from "../../../../assets/images/article-url-image.png"
import videoImage from "../../../../assets/images/video-url-image.png"
import {Box, Grid, Typography} from "@mui/material";
import {InfoOutlined as InfoIconOutlined} from "@mui/icons-material";
import {searchInfoCardStyle} from "./SearchInfoCardStyle";

export const SearchInfoCard = () => {
	return (
		<PaperGrid variant={"outlined"}
		           sxPaperOverride={searchInfoCardStyle.paperOverride}>
			<Grid container item spacing={3} wrap={"nowrap"}>
				<Grid item>
					<InfoIconOutlined sx={searchInfoCardStyle.icon}/>
				</Grid>
				<Grid item sx={searchInfoCardStyle.text}>
					<Typography textAlign={"left"}>
						To search for content, enter an article’s Content ID or a video’s Media ID. Find an
						<Box fontWeight={700} display="inline"> article’s Content ID by looking at the last digits of an
							article’s cbc.ca URL. </Box>Find a
						<Box fontWeight={700} display="inline"> video’s Media ID by looking at the last digits of a
							video’s
							cbc.ca URL.
						</Box>
					</Typography>
				</Grid>
			</Grid>
			<Grid item>
				<img src={articleImage}
				     style={searchInfoCardStyle.image}/>
			</Grid>
			<Grid item>
				<img src={videoImage}
				     style={searchInfoCardStyle.image}/>
			</Grid>
		</PaperGrid>
	)
}