import React, {useState} from "react";
import {Avatar, Chip, ListItemButton, Popover} from "@mui/material";
import {defaultDrawerListItemButtonStyle, drawerHoverListUserButtonStyle
} from "../../../../assets/styles/styles";
import {userDrawerListItemButtonStyle} from "./UserDrawerListItemButtonStyle";
import { LogOutButton } from "../../../Foundational/LogOutButton/LogOutButton";
import PropTypes from "prop-types";

export const UserDrawerListItemButton = ({item, drawerOpen}) => {
	const [anchor, setAnchor] = useState(null)
	let open = Boolean(anchor)
	return (
		<>
		{React.cloneElement(
			<ListItemButton
			sx={(theme) => ({...defaultDrawerListItemButtonStyle, ...drawerHoverListUserButtonStyle})}
			>
				<Avatar sx={userDrawerListItemButtonStyle(drawerOpen).avatar}>
					{item.name.charAt(0)}
				</Avatar>
				<Chip
					sx={userDrawerListItemButtonStyle(drawerOpen).chip}
					variant={"outlined"}
					label={item.name}
					avatar={
						<Avatar sx={userDrawerListItemButtonStyle(drawerOpen).avatarBasic}>
							{item.name.charAt(0)}
						</Avatar>}
				/>
			</ListItemButton>
		, {
			onClick: (event)=>setAnchor(event.currentTarget),
		})}
		<Popover
			open={open}
			anchorEl={anchor}
			anchorOrigin={{
				vertical: "top",
				horizontal: "center", 
			}}
			transformOrigin={{
				vertical: "bottom", 
				horizontal: "center", 
			}}
			onClose={()=>{
				setAnchor(null)
			}}
			disableRestoreFocus
		>
				<LogOutButton setAnchor={setAnchor}/>
		</Popover>
	</>
	)
}

UserDrawerListItemButton.propTypes = {
	item: PropTypes.object.isRequired,
	drawerOpen: PropTypes.bool.isRequired,
}