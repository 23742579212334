import {getFormattedDate} from "../../../utils/formatters";
import apiCall from "../../../utils/apiCall";

export const title = "Embedded in which stories?"
export const emptyMessage = "Embedded stories data currently unavailable"

export const articlesWithVideoEmbedApi = (contentId, dateRange, setDataInfo) => {
	setDataInfo((prevData) => ({...prevData, loading: true, error:false}))

	const startDate = getFormattedDate(dateRange[0])
	const endDate = getFormattedDate(dateRange[1])
	const query = `
            query articlesWithVideoEmbed($startDate: String!, $endDate: String!, $contentId: String!) {
                articlesWithVideoEmbed(startDate: $startDate, endDate: $endDate, contentId: $contentId) {
                    contentId
                    contentHeadline
                    publishTime
                    metrics {
                        content {
                            watchedStreams
                            streams
                            watched
                        }
                        cbc {
                            watchedStreams
                            streams
                            watched
                        }
                    }
                }
            }
        `;

	const queryVariable = {
		startDate: startDate,
		endDate: endDate,
		contentId: contentId
	};

	apiCall(query, queryVariable)
		.then(response => {
			const {articlesWithVideoEmbed: articlesWithVideoEmbedData} = response;
            if (articlesWithVideoEmbedData.length === 0){
                throw new Error(`embedded stories query returned an empty list`)
            }

            setDataInfo({
                data: articlesWithVideoEmbedData,
                loading: false,
                error:false
            })

		}).catch(error => {
            console.log({articlesWithVideoEmbedError:error})
            setDataInfo({
                data: [],
                loading: false,
                error:true
            })
	});

}