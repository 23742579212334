import React, {useEffect, useState} from "react";
import {Container, Grid, Typography} from "@mui/material";
import {IllustrationProgress} from "../../../Loading/Progress/IllustrationProgress/IllustrationProgress";
import {Header} from "../../../Header/Header";
import {GraphCard} from "../../../Cards/GraphCard/GraphCard";
import {TagCard} from "../../../Cards/Content/Tags/TagCard/TagCard";
import {LineupCard} from "../../../Cards/LineupCard/LineupCard";
import {HeadlineCard} from "../../../Cards/HeadlineCard/HeadlineCard";
import {BeforeAfterCard} from "../../../Cards/BeforeAfterCard/BeforeAfterCard";
import {AlertCard} from "../../../Cards/Alerts/AlertCard/AlertCard";
import {
	alertMessages,
	alertSeverity,
	alertTitle,
	beforeAfterTableColumnsVisibilityModel,
	fetchContentId,
	searchPlaceholder,
	validateContentId
} from "./DCADHelper";
import {SearchInfoCard} from "../../../Cards/Info/SearchInfoCard/SearchInfoCard";
import {DateInfoCard} from "../../../Cards/Info/DateInfoCard/DateInfoCard";
import {Link} from "@material-ui/core";
import {SearchInput} from "../../../Foundational/SearchInput/SearchInput";
import {useHistory, useParams} from "react-router-dom";
import {DCADStyle} from "./DCADStyle";
import {Notification} from "../../../Foundational/Notification/Notification";
import {DCADGroup} from "../../../Cards/KPI/DCADGroup/DCADGroup";
import {CoreDateRangePicker} from "../../../Foundational/CoreDateRangePicker/CoreDateRangePicker"
import { NotificationCard } from "../../../Cards/NotificationCard/NotificationCard";
import { emptyMessage, emptyTitle, errorMessage, errorTitle } from "../../Dashboard/DashboardHelper";
import { StandaloneVideoInsightsCard } from "../../../Cards/StandaloneVideoInsights/StandaloneVideoInsightsCard";
import { StandaloneClicksCard } from "../../../Cards/StandaloneClicksCard/StandaloneClicksCard";
import { VideoPerformanceCard } from "../../../Cards/VideoPerformance/VideoPerformance";
import { EmbeddedVideoCard } from "../../../Cards/EmbeddedVideoCard/EmbeddedVideoCard";
import { EmbeddedStoryCard } from "../../../Cards/EmbeddedStoryCard/EmbeddedStoryCard";

const storageKey = "dateRangeDCAD"
const referrerStorageKey = "referrer"

export const DCAD = ({drawerOpen, dateRangeLowerBound}) => {
	const {contentId} = useParams()
	const history = useHistory()
	const referrer = sessionStorage.getItem(referrerStorageKey)
	const referrerDateRange = referrer != null ? JSON.parse(referrer) : null
	const sessionDateRange = referrer && referrerDateRange != null ? referrerDateRange : JSON.parse(sessionStorage.getItem(storageKey)) 
	const [dateRangeAlert, setDateRangeAlert] = useState({
		active: false,
		severity: "warning",
		body: "Sorry! Date ranges longer than 365 days cannot be processed right now."
	})
	const initialDateRange = sessionDateRange ? [new Date(sessionDateRange[0]), new Date(sessionDateRange[1])] : null
	const [minDate, setMinDate] = useState(dateRangeLowerBound)
	const [dateRange, setDateRange] = useState({
		selected: initialDateRange, validated: initialDateRange
	})

	const [anyChildHasError, setAnyChildHasError] = useState(false)
	const [anyChildIsEmpty, setAnyChildIsEmpty] = useState(false)
	const [validation, setValidation] = useState({isValidating: true, error: false, contentType:"Text"})
	const [columnVisibilityModel, setColumnVisibilityModel] = useState(beforeAfterTableColumnsVisibilityModel)
	useEffect(() => {
		fetchContentId(contentId).then((response) => validateContentId(response, dateRange, setMinDate, setDateRange, setValidation)).catch((error) => {
			setValidation({isValidating: false, error: true})
		})
	}, [contentId])

	if (validation.isValidating) {
		return <IllustrationProgress/>
	}
	return (
		<>
			<Header drawerOpen={drawerOpen}
			        datePicker={<CoreDateRangePicker dateRange={dateRange} setDateRange={setDateRange}
			                                         alert={dateRangeAlert}
			                                         setAlert={setDateRangeAlert}
			                                         minDate={minDate} storageKey={storageKey}
													 setEmpty={setAnyChildIsEmpty} setHasError={setAnyChildHasError} />
													}
			        alert={dateRangeAlert}
			        setAlert={setDateRangeAlert}
			        search={<SearchInput placeholderText={searchPlaceholder} sxOverride={{
				        input: DCADStyle.input,
				        paper: DCADStyle.paper,
				        button: DCADStyle.button
			        }} action={(event) => {
				        if (event.target?.elements["0"]?.value) {
					        history.push(`/DCAD/${(event.target?.elements["0"]?.value|| "").trim()}`);
				        }
			        }}/>}
			/>

			<Container sx={DCADStyle.container}>
				{validation.error ?
					<Grid container direction={"column"} spacing={2}>
						<Grid item>
							<AlertCard title={alertTitle} messages={alertMessages} severity={alertSeverity}/>
						</Grid>
						<SearchInfoCard/>
						<DateInfoCard/>
						<Grid item>
							<Typography textAlign={"left"} sx={DCADStyle.mail}>
								If you have any questions please message CBC’s Machine Intelligence & Retention team
								at <Link href="mailto:mir@cbc.ca">mir@cbc.ca</Link>.
							</Typography>
						</Grid>
					</Grid> :
					<Grid container direction={"column"} spacing={3}>
						<NotificationCard
							title={errorTitle}
							message={errorMessage}
							severity={"error"}
							open={false}
							handleClose={()=>setAnyChildHasError(false)}
							/>
						<NotificationCard
							title={emptyTitle}
							message={emptyMessage}
							severity={"info"}
							open={false}
							handleClose={()=>setAnyChildIsEmpty(false)}
							/>
						<GraphCard contentId={contentId} dateRange={dateRange.validated} setEmpty={setAnyChildIsEmpty} setHasError={setAnyChildHasError}/>
						<DCADGroup contentId={contentId} dateRange={dateRange.validated} setEmpty={setAnyChildIsEmpty} setHasError={setAnyChildHasError}/>
						{validation.contentType === "Video" && <VideoPerformanceCard contentId={contentId} dateRange={dateRange.validated}/>}
						<LineupCard contentId={contentId} dateRange={dateRange.validated} setEmpty={setAnyChildIsEmpty} setHasError={setAnyChildHasError}/>
						{validation.contentType === "Text" && <EmbeddedVideoCard contentId={contentId} dateRange={dateRange.validated} />}
						{validation.contentType === "Video" && <StandaloneClicksCard contentId={contentId} dateRange={dateRange.validated}/>}
						{validation.contentType === "Video" && <StandaloneVideoInsightsCard contentId={contentId} dateRange={dateRange.validated}/>}
                        {validation.contentType === "Video" && <EmbeddedStoryCard contentId={contentId} dateRange={dateRange.validated}/>}
						<HeadlineCard contentId={contentId} dateRange={dateRange.validated} setEmpty={setAnyChildIsEmpty} setHasError={setAnyChildHasError}/>
						<BeforeAfterCard contentId={contentId} dateRange={dateRange.validated} 
						                 columnVisibilityModel={columnVisibilityModel}
						                 setColumnVisibilityModel={setColumnVisibilityModel}  setEmpty={setAnyChildIsEmpty} setHasError={setAnyChildHasError}/>
						<TagCard contentId={contentId} dateRange={dateRange.validated} setEmpty={setAnyChildIsEmpty} setHasError={setAnyChildHasError}/> 
					</Grid>}
			</Container>
			<Notification/>
		</>
	)
}