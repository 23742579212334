import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { PaperGrid } from "../../Foundational/PaperGrid/PaperGrid";
import { emptyMessage, kpiGroups, standaloneVideoInsightsApi, tableTitle, title } from "./StandaloneVideoInsightsHelper";
import { MediaKPIGroup } from "../KPI/MediaKPIGroup/MediaKPIGroup";
import { StandaloneVideoInsightsTable } from "../../Tables/StandaloneVideoInsightsTable/StandaloneVideoInsightsTable";
import { StateCard } from "../StateCard/StateCard";
import { LargeTableSkeleton } from "../../Loading/Skeleton/LargeTableSkeleton/LargeTableSkeleton";
import { standaloneVideoInsightsStyle } from "./StandaloneVideoInsightsStyle";
import PropTypes from "prop-types";

export const StandaloneVideoInsightsCard = ({contentId, dateRange}) => {
    const [dataInfo, setDataInfo] = useState({ loading: true, error: false, data: {} });
    useEffect(() => standaloneVideoInsightsApi(contentId, dateRange, setDataInfo), [contentId, dateRange])

    if (dataInfo.loading || dataInfo.error) {
        return (
            <StateCard loading={dataInfo.loading} error={dataInfo.error}
                       component={<LargeTableSkeleton/>}
                       message={emptyMessage}/>
        )
    }

    return (
        <PaperGrid >
            <Grid container direction="column" spacing={6}>
                <Grid item>
                    <Typography sx={standaloneVideoInsightsStyle.title}>{title}</Typography>
                </Grid>

                <Grid container item direction="row" justifyContent="space-between" spacing={2} wrap="nowrap">
                    <Grid container item direction={"row"} justifyContent={"space-between"} spacing={2} wrap={"nowrap"}>
                        {kpiGroups.map((kpiGroup) => {
                            return (
                                <Grid container item direction={"column"} spacing={3}>
                                    <Grid container item direction={"row"} justifyContent={"start"} wrap={"nowrap"} spacing={1}>
                                        <Grid item>
                                            <Typography sx={standaloneVideoInsightsStyle.subtitle}>
                                                {kpiGroup.title}
                                            </Typography>
                                        </Grid>
                                        <Grid item sx={standaloneVideoInsightsStyle.icon}>
                                            <InfoOutlinedIcon />
                                        </Grid>
                                    </Grid>
                                    <Grid item>
                                        <MediaKPIGroup data={dataInfo.data.kpiData[kpiGroup.accessor]}/>
                                    </Grid>
                                </Grid>
                            )})}
                    </Grid>
                </Grid>

                <Grid container item direction={"column"} spacing={2}>
                    <Grid item>
                        <Typography sx={standaloneVideoInsightsStyle.subtitle}>{tableTitle}</Typography>
                    </Grid>
                    <Grid item>
                        <StandaloneVideoInsightsTable  tableData={dataInfo.data.tableData} />
                    </Grid>
                </Grid>
            </Grid>
        </PaperGrid>
    );
};

StandaloneVideoInsightsCard.propTypes = {
    dateRange: PropTypes.array.isRequired,
    contentId: PropTypes.string.isRequired,
}
