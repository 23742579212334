import axios from "axios";
import {API_ENDPOINT, ID_TOKEN_STORAGE} from "../assets/styles/variables";
import jwt from "jwt-decode"
import {firebaseAuth} from "../firebase/firebase";

const handleErrors = (errors, setAlert) => {
	errors.forEach(error => {
		if (error.message.toLowerCase().includes("unauthorized")) {
			setAlert({
				message: "Your session has expired",
				actionLabel: "Continue with Google",
				action: () => {
					window.location.reload();
				}
			});
		}
	});
	throw new Error(JSON.stringify(errors));
};

const apiCall = (query, variables, setAuth, setAlert = () => {
}) => {
	const idToken = localStorage.getItem(ID_TOKEN_STORAGE);
	if (!idToken) window.location.assign("/");
	const currentUser = firebaseAuth.currentUser
	const payload = jwt(idToken)
	if (payload.exp < Date.now() / 1000) {
		localStorage.removeItem(ID_TOKEN_STORAGE)
		window.location.reload();
	} else if (currentUser) {
		currentUser.getIdToken(true).then((idToken) => {
			localStorage.setItem(ID_TOKEN_STORAGE, idToken);
		})
	}
	const config = {
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
			Authorization: idToken,
		}
	};
	return axios
		.post(API_ENDPOINT, {query, variables}, config)
		.then(response => {
			const {data: apiResponse} = response;
			if (!apiResponse) throw new Error("Something went wrong.");

			const {data, errors} = apiResponse;
			if (errors && errors.length > 0) handleErrors(errors, setAlert);
			if (!data) throw new Error("Something went wrong.");

			return data;
		})
		.catch(error => {
			console.log({apiError: error});
		});
};

export default apiCall;
