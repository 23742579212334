import React from "react";
import {Avatar, Chip, Divider, Grid, Typography} from "@mui/material";
import {contentDetailStyle} from "./ContentDetailStyle";
import {getURL} from "./ContentDetailHelper"
import {getFormattedDateString} from "../../../../utils/formatters";
import PropTypes from "prop-types"
import {OnClickPopover} from "../../../Foundational/Utils/OnClickPopover/OnClickPopover";

export const ContentDetail = ({contentDetail, contentOverview, contentEmbeds, embedCard}) => {
	const url = getURL(contentDetail.contentSubType)
	const showChipDivider = contentEmbeds && (contentEmbeds.length > 1 || (contentEmbeds.length === 1 && (contentEmbeds[0].num === undefined || contentEmbeds[0].num > 0)))
	return (
		<Grid container item direction={"column"} spacing={1}>
			<Grid container item direction={"row"} spacing={1}>
				<Grid item>
					<Typography sx={contentDetailStyle.headline} variant={"h5"} onClick={() => {
						window.open(url, '_blank').focus();
					}}>
						{contentDetail.headline}
					</Typography>
				</Grid>
			</Grid>
			{contentDetail.publishTime &&
			<Grid container item direction={"row"}>
				<Grid item>
					<Typography sx={contentDetailStyle.time} variant={"body2"}>
						{"Published: " + getFormattedDateString(contentDetail.publishTime) + " ET"}
					</Typography>
				</Grid>
				{contentDetail.lastUpdate &&
				<>
					<Divider sx={contentDetailStyle.divider} orientation={"vertical"} light={true}
					         flexItem={true}/>
					<Grid item>
						<Typography sx={contentDetailStyle.time} variant={"body2"}>
							{"Updated: " + getFormattedDateString(contentDetail.lastUpdate) + " ET"}
						</Typography>
					</Grid>
				</>}
			</Grid>}
			<Grid container item direction={"row"} spacing={1}>
				{contentOverview && contentOverview.map((label, index) => {
					return (index < 4 &&
						<Grid item key={label + "-" + index}>
							<Chip
								sx={contentDetailStyle.chip}
								label={label}
								size={"small"}
							/>
						</Grid>)
				})}
				{showChipDivider &&
				<Divider sx={contentDetailStyle.chipDivider} orientation={"vertical"} light={true} flexItem={true}/>}
				{contentEmbeds && contentEmbeds.map((embed, index) => {
					const embedChip = <Chip
						label={embed.title}
						size={"small"}
						sx={embed.num == undefined || embed.num > 0 ? contentDetailStyle.embed : contentDetailStyle.hide}
						variant={embed.num ? "default" : "outlined"}
						avatar={embed.num && <Avatar sx={contentDetailStyle.avatar}>{embed.num}</Avatar>}
					/>
					return (index < 5 &&
						<Grid item key={embed.title + index}>
							{embedCard && (embed.title in embedCard) ?
								<OnClickPopover popoverBody={embedCard[embed.title]}>
									{embedChip}
								</OnClickPopover> :
								embedChip
							}
						</Grid>
					)
				})}
			</Grid>
		</Grid>
	)
}

ContentDetail.propTypes = {
	contentDetail: PropTypes.object,
	contentOverview: PropTypes.array,
	contentEmbeds: PropTypes.array,
	embedCard: PropTypes.object
}