import React, {useEffect, useState} from "react";
import {PaperGrid} from "../../../../Foundational/PaperGrid/PaperGrid"
import {StateCard} from "../../../StateCard/StateCard";
import {Chip, Grid, Typography} from "@mui/material";
import {Info as InfoIcon, LocalOffer as TagIcon} from "@mui/icons-material"
import {tagCardStyle} from "./TagCardStyle"
import {emptyMessage, tagCardApi} from "./TagCardHelper";
import {ContentTagSkeleton} from "../../../../Loading/Skeleton/ContentTagSkeleton/ContentTagSkeleton";
import PropTypes from "prop-types";


export const TagCard = ({contentId, dateRange, setHasError}) => {
	const [dataInfo, setDataInfo] = useState({data: [], loading: true, error: false})
	useEffect(() => tagCardApi(contentId, dateRange, setDataInfo, setHasError), [contentId, dateRange])

	if (dataInfo.loading || dataInfo.error) {
		return <StateCard loading={dataInfo.loading} error={dataInfo.error}
		                  component={<ContentTagSkeleton/>}
		                  sxPaperOverride={tagCardStyle.shortCardWidth}
		                  message={emptyMessage}/>
	}

	return (
		<PaperGrid elevation={1}
		           sxPaperOverride={dataInfo.data && dataInfo.data.length > 3 ? tagCardStyle.regularCardWidth : tagCardStyle.shortendCardWidth}>
			<Grid container item direction={"row"} justifyContent={"space-between"} wrap={"nowrap"} spacing={1}>
				<Grid item>
					<Chip
						label={<Typography sx={tagCardStyle.titleChipLabel}> Tags </Typography>}
						icon={<TagIcon sx={tagCardStyle.titleChipIcon}/>}
						sx={tagCardStyle.titleChip}
					/>
				</Grid>
				<Grid container item direction="row" justifyContent="center" spacing={2}>
					{dataInfo.data.map((tag, index) => (
						<Grid item sx={tagCardStyle.grid} key={tag + index}>
							<Chip
								label={tag}
								sx={tagCardStyle.contentChip}
							/>
						</Grid>
					))}
				</Grid>
				<Grid item>
					<InfoIcon sx={tagCardStyle.info}/>
				</Grid>
			</Grid>
		</PaperGrid>
	)
}

TagCard.propTypes = {
	contentId: PropTypes.string.isRequired,
	dateRange: PropTypes.arrayOf(PropTypes.shape({
		startDate: PropTypes.instanceOf(Date).isRequired,
		endDate: PropTypes.instanceOf(Date).isRequired
	})).isRequired,
	setHasError:PropTypes.func.isRequired,
}