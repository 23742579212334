import React from "react";
import { Paper, Stack, Typography, Grid } from "@mui/material";
import { detailPanelMetricsStyle } from "./DetailPanelMetricsStyle";
import {MediaKPIGroup} from "../../../../Cards/KPI/MediaKPIGroup/MediaKPIGroup"
import PropTypes from "prop-types";
import { kpiGroups } from "./DetailPanelMetricsHelper";
export const DetailPanelMetric = ({ row }) => {
    return (
        <Stack
        sx={detailPanelMetricsStyle.outerStack}
        direction={"column"}
      >
        <Paper sx={detailPanelMetricsStyle.paper}>
          <Stack direction={"column"} spacing={2}>
				<Grid container item direction={"row"} justifyContent={"space-between"} spacing={2} wrap={"nowrap"}>
                    {kpiGroups.map((kpiGroup) => {
                        return (
                        <Grid container item direction={"column"} spacing={3}>
                            <Grid container item direction={"row"} justifyContent={"start"} wrap={"nowrap"} spacing={1}>
                                <Grid item>
                                    <Typography sx={detailPanelMetricsStyle.subtitle}>
                                        {kpiGroup.title}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <MediaKPIGroup data={row.metrics[kpiGroup.accessor]}/>
                            </Grid>
                        </Grid>
                        )})}
                </Grid>
          </Stack>
        </Paper>
      </Stack>
    );
  };
  

DetailPanelMetric.propTypes = {
    row: PropTypes.object.isRequired,
  }