import {amountFormatter, secondsToTime} from "../../utils/formatters";

export const valueFormatter = (params) => (amountFormatter(params.value))
export const timeFormatter = (params) => (secondsToTime(params.value))
export const csvTimeFormatter = (params) => {
	let date = new Date(params.value * 1000)
	let duration = date.toTimeString().split(' ')[0];
	return duration
}

export function getContent(params) {
	let timestamp, options
	timestamp = new Date(params.row[this.timestamp])
	options = {year: "numeric", month: "long", day: "numeric", hour: "numeric", minute: "numeric"};
	return {
		"title": params.row[this.title],
		"timestamp": timestamp.toLocaleString("en-US", options),
		"type": this.type ? params.row[this.type] : null,
		"contentId": this.contentId ? params.row[this.contentId] : null,
		"referrer": this.referrer ? this.referrer : null
	}
}

export function getNav(params) {
	return {
		"type": this.type ? params.row[this.type] : null,
		"contentId": this.contentId ? params.row[this.contentId] : null,
		"referrer": this.referrer ? this.referrer : null,
		"dateRange": this.dateRange ? this.dateRange : null
	}
}

export function getZonePageViews(params){
	return {
		averagePageViews: params.row["averagePageViews"],
		zone: params.row["zone"]
	}
}