import {valueFormatter} from "../TablesHelper";
import React from "react"
import {
	GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
  } from "@mui/x-data-grid-pro";
import { RenderZoneCell } from "../RenderCell/RenderZoneCell/RenderZoneCell";
import { CustomToggle } from "./DetailPanel/CustomToggle/CustomToggle";

export const tagStatsColumns = [
	{
		field: "tag",
		headerName: "Subjects",
		flex: 1,
		editable: false,
		disableColumnMenu: true,
		headerAlign: "left",
		align:"left",
		cellClassName: "tag-name"
	},
	{
		field: "commonlyUsedWith",
		headerName: "Often Used With",
		flex: 1,
		editable: false,
		disableColumnMenu: true,
		headerAlign: "left",
		align:"left",
	},
	{
		field: "numContent",
		headerName: "Pieces of Content",
		flex: 1,
		valueFormatter: valueFormatter,
		cellClassName: "numbers",
		editable: false,
		disableColumnMenu: true,
		headerAlign: "center",
		align:"center"
	},
    {
		field: "pageViews",
		headerName: "Total Page Views",
		flex: 1,
		valueFormatter: valueFormatter,
		cellClassName: "numbers",
		editable: false,
		disableColumnMenu: true,
		headerAlign: "center",
		align:"center"
	},
    {
		field: "averagePageViews",
		headerName: "Average Page Views",
		flex: 1,
		editable: false,
		disableColumnMenu: true,
		headerAlign: "left",
		align:"center",
		valueFormatter:valueFormatter,
        renderCell: RenderZoneCell
	},
	{
		...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
		headerName:"Detail Panel Toggle",
		renderCell: (params) => (
		  <CustomToggle isExpanded={params.value} />
		),
	  },
]