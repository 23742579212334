import React from "react";
import {Alert, AppBar, Container, Grid, Toolbar} from "@mui/material";
import {headerStyle} from "./HeaderStyle";
import {Grouper} from "../Foundational/Utils/Grouper/Grouper"

export const Header = ({drawerOpen, alert, setAlert, search, datePicker, dropdown}) => {
	const items = datePicker && dropdown?.length > 0 ? [datePicker, ...dropdown] : datePicker ? [datePicker, search] : [...dropdown]
	return (
		<AppBar
			sx={drawerOpen ? {...headerStyle.header, ...headerStyle.shift} : headerStyle.header}
			position={"fixed"}
		>
			{alert && alert.active &&
			<Alert onClose={() => {
				setAlert({...alert, active: false})
			}}
			       severity={alert.severity}>
				{alert.body}
			</Alert>}
			<Toolbar>
				<Container maxWidth={"lg"} sx={headerStyle.container}>
					<Grid container direction={"row"} justifyContent={"center"} spacing={1}>
						{items.length > 0 &&
						<Grid item>
							<Grouper items={items}/>
						</Grid>
						}
					</Grid>
				</Container>
			</Toolbar>
		</AppBar>
	)
}