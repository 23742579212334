import React from "react";
import {Divider, Grid, Typography} from "@mui/material";
import {kpiStyle} from "./KPIStyle";
import PropTypes from "prop-types";
import CountUp from "react-countup";
import {PaperGrid} from "../../Foundational/PaperGrid/PaperGrid";

export const KPI = ({title, values, duration, units, tooltip}) => {
	const valuesArray = Array.isArray(values) ? values : [values];
	const countupValues = valuesArray.map((value) => {
	  if (units === "time") {
		const hours = Math.floor(value / 3600);
		const minutes = Math.floor((value % 3600) / 60);
		const seconds = value % 60;
		return [
		  ...(hours ? [{ end: hours, suffix: "h" }] : []),
		  ...(minutes ? [{ end: minutes, suffix: "m" }] : []),
		  ...(hours.toString().length <= 4 ? [{ end: seconds, suffix: "s" }] : []),
		];
	  }
	  return { end: value, suffix: units || "" };
	});
	return (
		<PaperGrid elevation={0} sxPaperOverride={kpiStyle.sxPaperOverride}>
			<Grid container item direction={"column"} justifyContent={"center"} sx={kpiStyle.grid}>
				<Grid container item direction={"row"} justifyContent={"start"} wrap={"nowrap"} spacing={1}>
					<Grid item>
						<Typography sx={kpiStyle.title}>
							{title}
						</Typography>
					</Grid>
					<Grid item sx={kpiStyle.icon}>
						{tooltip}
					</Grid>
				</Grid>
				<Divider/>
				<Grid container item wrap="nowrap" sx={kpiStyle.value} spacing={1}>
				{countupValues.map((value, index) => (
                        <React.Fragment key={`${value.end}-${value.suffix}-${index}`}>
                            {Array.isArray(value)
                                ? value.map((timeSegment) => (
                                    <Grid item key={`${timeSegment.end}-${timeSegment.suffix} - ${index}`}>
                                        <CountUp
                                            end={timeSegment.end}
                                            duration={duration ? duration: 2}
                                            separator=","
                                            decimals={0}
                                            suffix={timeSegment.suffix}
                                        />
                                    </Grid>
                                ))
                                : (
                                    <Grid item>
                                        <CountUp
                                            end={value.end}
                                            duration={duration ? duration : 2}
                                            separator={","}
                                            decimals={0}
                                            suffix={value.suffix}
                                        />
                                    </Grid>
                                )
                            }
                            {index !== countupValues.length - 1 && (
                                <Grid item>
                                    <Typography sx={kpiStyle.separator}> - </Typography>
                                </Grid>
                            )}
                        </React.Fragment>
                    ))}
				</Grid>
			</Grid>
		</PaperGrid>
	)
}

KPI.propTypes = {
    title: PropTypes.string.isRequired,
    values: PropTypes.oneOfType([PropTypes.number, PropTypes.arrayOf(PropTypes.number)]).isRequired,
    duration: PropTypes.number,
    units: PropTypes.string.isRequired,
    tooltip: PropTypes.node,
};