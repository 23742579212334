export const primaryKPIs = new Set(["watchedStreams"])
export const kpis = [
	{
		title: "%Watched/Streams",
		accessor: "watchedStreams",
		duration: 1,
		units: "%",
	},
	{
		title: "Streams",
		accessor: "streams",
		duration: 1.5,
		units: null,
	},
	{
		title: "Watched",
		accessor: "watched",
		duration: 2,
		units: null,
	}];