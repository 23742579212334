import * as React from "react";
import logo from "../../../../assets/images/logo-red.svg";
import {Box, Grid} from "@mui/material";
import {logoProgressStyle, useStyles } from "./LogoProgressStyle";

export const LogoProgress = ({overlay = false}) => {

    const classes = useStyles()

    return (
        <Box sx={{...(overlay ? logoProgressStyle.overlay : logoProgressStyle.fill)}}>
            <Grid container direction={"column"} justifyContent={"center"} alignContent={"center"}>
                <Grid item>
                    <img src={logo} height={150} width={150} className={classes.pingAnimation}/>
                </Grid>
            </Grid>
        </Box>
    )
}