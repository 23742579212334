import React from "react";
import {Alert, AlertTitle, Box} from "@mui/material"
import {alertCardStyle} from "./AlertCardStyle";

export const AlertCard = ({title, messages, severity}) => {
	return (
		<Alert severity={severity}>
			<AlertTitle sx={alertCardStyle.title}>
				<Box>
					{title}
				</Box>
			</AlertTitle>
			{messages && messages.map(
				function alertMessageReturn(message, index) {
					return (
						<Box key={index} sx={message.style ? message.style : alertCardStyle.message}>
							{message.text}
						</Box>
					)
				})}
		</Alert>
	)
}