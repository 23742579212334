export const standaloneVideoInsightsTableColumns = [
    {
      field: "page",
      headerName: "Page",
      disableColumnMenu: true,
      flex: 1,
      editable: false,
    },
    {
      field: "playNext",
      headerName: "Play Next",
      disableColumnMenu: true,
      flex: 1,
      editable: false,
    },
    {
      field: "streams",
      headerName: "Streams",
      disableColumnMenu: true,
      flex: 1,
      editable: false,
      cellClassName: "numbers",
    },
    {
      field: "watched",
      headerName: "Watched",
      disableColumnMenu: true,
      editable: false,
      flex: 1,
      cellClassName: "numbers",
    },
  ];