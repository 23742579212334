import React from "react";
import {DataGridPremium,} from "@mui/x-data-grid-premium";
import { columnLocalText } from "../TableToolbar/TableToolbar";
import { CustomNoRowsOverlay } from "../CustomNoRowsOverlay/CustomNoRowsOverlay"
import { embeddedStoriesColumns } from "./EmbeddedStoriesTableHelper";
import { embeddedStoriesTableStyle } from "./EmbeddedStoriesTableStyle";
import { DetailPanelMetric } from "./DetailPanel/DetailPanelMetrics/DetailPanelMetrics";

export const EmbeddedStoriesTable = ({articlesWithVideoEmbedData}) => {
	return (
		<DataGridPremium
			sortingOrder={["asc", "desc"]}
			columns={embeddedStoriesColumns}
			rows={articlesWithVideoEmbedData}
			components={{
			    noRowsOverlay: CustomNoRowsOverlay
			}}
			localeText={{toolbarColumns: columnLocalText}}
			getDetailPanelContent={DetailPanelMetric}
            getDetailPanelHeight={() => 250}
			columnHeaderHeight={38}
			rowHeight={articlesWithVideoEmbedData.length === 0 ? 76 : 57}
			autoHeight={true}
			disableRowSelectionOnClick={true}
            getRowId={(row) => row.contentHeadline + "-" + row.publishTime}
			sx={embeddedStoriesTableStyle}
		/>
	);
}