import React, { useState } from "react";
import { Grid, Button, Typography, Popover } from "@mui/material"
import { groupByKey } from "../../../utils/utils";
import { departmentDropdownStyle } from "./DepartmentDropdownStyle";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { renderColumn } from "./DepartmentDropdownHelper";

export function DepartmentDropdown({ department, departmentList, handleDepartmentChange }) {
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const departments = groupByKey(departmentList, "header")

  return (
    <>
      <Button onClick={(event) => {setAnchorEl(event.currentTarget)}} sx={departmentDropdownStyle.labelButton}
        endIcon={<ArrowDropDownIcon />} 
        >
        {department.name}
      </Button>
      <Popover
          id={open ? "department-dropdown" : null}
          open={open}
          anchorEl={anchorEl}
          onClose={()=>setAnchorEl(null)}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          PaperProps={{sx:departmentDropdownStyle.paperProps}}
      >
                <Grid container direction={"row"} spacing={1} wrap={"nowrap"} sx={departmentDropdownStyle.grid.padding}>
                    <Grid container item sx={departmentDropdownStyle.grid.columnWidth} direction={"column"} wrap={"nowrap"}>
                        <Grid item>
                            <Typography sx={departmentDropdownStyle.columnLabel}>Region News</Typography>
                        </Grid>
                        {renderColumn(departments["Region News"], handleDepartmentChange, ()=>setAnchorEl(null))}
                    </Grid>
                    <Grid container item sx={departmentDropdownStyle.grid.columnWidth}  direction={"column"} wrap={"nowrap"}>
                      <Grid container item direction={"column"}>
                          <Grid item>
                              <Typography sx={departmentDropdownStyle.columnLabel}>Network News</Typography>
                          </Grid>
                          {renderColumn(departments["Network News"], handleDepartmentChange, ()=>setAnchorEl(null))}
                      </Grid>
                      <Grid container item direction={"column"}>
                          <Grid item>
                              <Typography sx={departmentDropdownStyle.columnLabel} paddingY={2}>Network Radio</Typography>
                          </Grid>
                          {renderColumn(departments["Network Radio"], handleDepartmentChange, ()=>setAnchorEl(null))}
                      </Grid>
                    </Grid>
                    
                    <Grid container item sx={departmentDropdownStyle.grid.columnWidth} direction={"column"} wrap={"nowrap"}>
                        <Grid container item direction={"column"}>
                        <Grid item>
                            <Typography sx={departmentDropdownStyle.columnLabel}>EFS</Typography>
                        </Grid>
                            {renderColumn(departments["EFS"], handleDepartmentChange, ()=>setAnchorEl(null))}
                        </Grid>
                        <Grid container item direction={"column"}>
                        <Grid item>
                            <Typography sx={departmentDropdownStyle.columnLabel} paddingY={2}>Other</Typography>
                        </Grid>
                            {renderColumn(departments["News - General"], handleDepartmentChange, ()=>setAnchorEl(null))}
                        </Grid>
                    </Grid>
                </Grid>
        </Popover>
      </>
  );
}
