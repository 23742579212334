import {Grid, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import {title, message, kpiGroups, videoPerformanceApi } from "./VideoPerformanceHelper"
import { StateCard } from "../StateCard/StateCard";
import {KPISkeleton} from "../../Loading/Skeleton/KPISkeleton/KPISkeleton"
import { MediaKPIGroup } from "../KPI/MediaKPIGroup/MediaKPIGroup";
import { videoPerformanceCardStyle } from "./VideoPerformanceStyle";
import { PaperGrid } from "../../Foundational/PaperGrid/PaperGrid";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import PropTypes from "prop-types";
export const VideoPerformanceCard = ({contentId, dateRange}) => {
	const [dataInfo, setDataInfo] = useState({loading: true, error: false, data: {}})
	useEffect(() => videoPerformanceApi(contentId, dateRange, setDataInfo), [dateRange, contentId])

	if (dataInfo.loading || dataInfo.error) {
		return (
			<StateCard loading={dataInfo.loading} error={dataInfo.error}
			           component={<KPISkeleton num={4}/>} message={message}/>
		)
	}
	return (
		<PaperGrid sx={videoPerformanceCardStyle.paperGrid}>
			<Grid container item direction={"column"} spacing={4}>
				<Grid item>
					<Typography sx={videoPerformanceCardStyle.title}>
						{title}
					</Typography>
				</Grid>
				<Grid container item direction={"row"} justifyContent={"space-between"} spacing={2} wrap={"nowrap"}>
					{kpiGroups.map((kpiGroup) => {
						return (
                        <Grid container item direction={"column"} spacing={3}>
                            <Grid container item direction={"row"} justifyContent={"start"} wrap={"nowrap"} spacing={1}>
                                <Grid item>
                                    <Typography sx={videoPerformanceCardStyle.subtitle}>
                                        {kpiGroup.title}
                                    </Typography>
                                </Grid>
                                <Grid item sx={videoPerformanceCardStyle.icon}>
                                    <InfoOutlinedIcon />
                                </Grid>
                            </Grid>
							<Grid item>
								<MediaKPIGroup data={dataInfo.data[kpiGroup.accessor]}/>
							</Grid>
						</Grid>
                        )})}
				</Grid>
			</Grid>
		</PaperGrid>
	)
}
VideoPerformanceCard.propTypes = {
	dateRange: PropTypes.array.isRequired,
	contentId: PropTypes.string.isRequired,
}