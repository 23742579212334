import React, {useState, useEffect} from "react";
import {Container, Grid} from "@mui/material";
import {Header} from "../../../Header/Header";
import {GraphTabsCard} from "../../../Cards/Graph/Tab/GraphTabsCard/GraphTabsCard";
import {TopNCard} from "../../../Cards/TopNCard/TopNCard";
import {OCADStyle} from "./OCADStyle";
import {topNTableColumnVisibilityModel} from "./OCADHelper";
import {OCADGroup} from "../../../Cards/KPI/OCADGroup/OCADGroup";
import {CoreDateRangePicker} from "../../../Foundational/CoreDateRangePicker/CoreDateRangePicker";
import {Notification} from "../../../Foundational/Notification/Notification";
import { NotificationCard } from '../../../Cards/NotificationCard/NotificationCard';
import { errorTitle, errorMessage, emptyTitle, emptyMessage} from "../../Dashboard/DashboardHelper";

const storageKey = "dateRangeOCAD"
export const OCAD = ({drawerOpen, dateRangeLowerBound, departmentFilter, department, departments}) => {
	const sessionDateRange = JSON.parse(sessionStorage.getItem(storageKey))
	const [dateRangeAlert, setDateRangeAlert] = useState({
		active: false,
		severity: "warning",
		body: "Sorry! Date ranges longer than 365 days cannot be processed right now."
	})
	const initialDateRange = sessionDateRange ? [new Date(sessionDateRange[0]),new Date(sessionDateRange[1])] : [new Date(),new Date()]
	const [minDate] = useState(dateRangeLowerBound)
	const [dateRange, setDateRange] = useState({
		selected: initialDateRange, validated: initialDateRange
	})

	const [anyChildHasError, setAnyChildHasError] = useState(false)
	const [anyChildIsEmpty, setAnyChildIsEmpty] = useState(false)
	const [columnVisibilityModel, setColumnVisibilityModel] = useState(topNTableColumnVisibilityModel)

	useEffect(()=>{
		setAnyChildHasError(false)
		setAnyChildIsEmpty(false)
	}, [department])

	return (<>
		<Header drawerOpen={drawerOpen}
		        datePicker={<CoreDateRangePicker dateRange={dateRange} setDateRange={setDateRange}
		                                         alert={dateRangeAlert}
		                                         setAlert={setDateRangeAlert}
												 storageKey={storageKey}
		                                         minDate={minDate}
												 setEmpty={setAnyChildIsEmpty} setHasError={setAnyChildHasError}
												 />}
		        alert={dateRangeAlert}
		        setAlert={setDateRangeAlert}
		        dropdown={[departmentFilter]}
		/>

		<Container sx={OCADStyle.container}>
			<Grid container direction={"column"} spacing={3}>
			<NotificationCard
							title={errorTitle}
							message={errorMessage}
							severity={"error"}
							open={false}
							handleClose={()=>setAnyChildHasError(false)}
							/>
						<NotificationCard
							title={emptyTitle}
							message={emptyMessage}
							severity={"info"}
							open={false}
							handleClose={()=>setAnyChildIsEmpty(false)}
							/>
				<OCADGroup dateRange={dateRange.validated} department={department} departments={departments} setEmpty={setAnyChildIsEmpty} setHasError={setAnyChildHasError}/>
				<GraphTabsCard dateRange={dateRange.validated} department={department} departments={departments} setEmpty={setAnyChildIsEmpty} setHasError={setAnyChildHasError}/>
				<TopNCard department={department} dateRange={dateRange.validated} departments={departments}
				          columnVisibilityModel={columnVisibilityModel} setEmpty={setAnyChildIsEmpty} setHasError={setAnyChildHasError}
				          setColumnVisibilityModel={setColumnVisibilityModel}/>
			</Grid>
		</Container>
		<Notification/>
	</>)
}