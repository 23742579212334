import React from "react";
import {formatterHelp, getAggregationLevel, getFormattedDate} from "../../../../../utils/formatters";
import {getDepartments} from "../../../../../utils/utils";
import apiCall from "../../../../../utils/apiCall";
import {LineGraph} from "../../../../Charts/LineGraph";
import {defaultPalette} from "../../../../../assets/styles/themes"
import { StackedLineGraph } from "../../../../Charts/StackedLineGraph";

export const chartMetricsAPI = (dateRange, department, departments, setDataInfo, setEmpty, setHasError) => {
	setDataInfo((prevState) => ({...prevState, loading: true, error:false}))

	let startDate, endDate, aggregationLevel, query;
	startDate = getFormattedDate(dateRange[0])
	endDate = getFormattedDate(dateRange[1])
	aggregationLevel = getAggregationLevel(dateRange[0], dateRange[1]);

	query = `
          query keyMetrics($startDate: String!, $endDate: String!, $aggregationLevel: String!, $department: [String!]!) {
            keyMetrics(startDate: $startDate, endDate: $endDate, aggregationLevel: $aggregationLevel, department: $department) {
              graphs {
                pageviewsStreams {
                  totalPageviews
                  totalStreams
                  dateTime
                }
                pageviewsReads {
                  totalPageviews
                  totalReads
                  dateTime
                }
                streamsWatched {
                  totalStreams
                  totalWatched
                  dateTime
                }
                avgTimeSpent {
                  avgTimeSpent
                  dateTime
                }
              }
            }
          }
        `;
	const variables = {
		startDate: startDate,
		endDate: endDate,
		aggregationLevel: aggregationLevel,
		department: getDepartments(department, departments),
	};
	apiCall(query, variables)
		.then(response => {

				const {keyMetrics: keyMetricsData} = response;
				let processedChartData = {}

				for (let [graphName, data] of Object.entries(keyMetricsData.graphs)) {
					let labels = Object.keys(data[0]).filter(label => label !== "dateTime");

					const updateLabels = () => {
						if (graphName !== "avgTimeSpent") {
							labels = labels.map((label) => (label.slice(5)))
							data = data.map((row) => {
								for (let [key, value] of Object.entries(row)) {
									if (key !== "dateTime") {
										row[key.slice(5)] = value
										delete row[key]
									}
								}
								return row
							})
						} else {
							let newLabel = "Average Time Spent"
							labels = labels.map((label) => (newLabel))
							data = data.map((row) => {
								for (let [key, value] of Object.entries(row)) {
									if (key !== "dateTime") {
										row[newLabel] = formatterHelp("amount", value)
										delete row[key]
									}
								}
								return row
							})
						}
					}

					const checkSum = (labels, data) => {
						let label1Sum, label2Sum
						label1Sum = data.reduce((a, b) => +a + +b[labels[0]], 0);
						label2Sum = labels.length > 1 ? data.reduce((a, b) => +a + +b[labels[1]], 0) : 0;
						return (label1Sum + label2Sum) <= 0
					}

					updateLabels()

					processedChartData[graphName] = {
						labels: labels,
						data: data,
						emptyMessage: graphName === "avgTimeSpent" ? `Key metrics for ${labels[0]} are currently unavailable.` : `Key metrics for ${labels[0]} & ${labels[1]} are currently unavailable.`,
						empty: checkSum(labels, data),
						error:false,
					}

					if (checkSum(labels, data)){
						setEmpty(true)
					}
				}
				setDataInfo({
					data: processedChartData,
					loading: false,
					error:false
				})
			}
		)
		.catch(error => {
			console.log({keyMetricsGraphError: error});
			setDataInfo((prevState) => ({...prevState, loading: false, error:true}))
			setHasError(true)
		});

}

export const graphColourPalette = {
	article: defaultPalette["Text"],
	video: defaultPalette["other"]
}

export const emptyMessage = "Key metrics are currently unavailable."

export const panels = [
	{
		label: "ALL PAGEVIEWS & STREAMS",
		accessor: "pageviewsStreams",
		colourPalette: graphColourPalette.article,
		chart: <StackedLineGraph/>,
		disabled: false
	},
	{
		label: "PAGEVIEWS & READ",
		accessor: "pageviewsReads",
		colourPalette: graphColourPalette.article,
		chart: <StackedLineGraph/>,
		disabled: false

	}, {
		label: "STREAMS & WATCHED",
		accessor: "streamsWatched",
		colourPalette: graphColourPalette.video,
		chart: <StackedLineGraph/>,
		disabled: false
	},
	{
		label: "AVERAGE TIME SPENT",
		accessor: "avgTimeSpent",
		colourPalette: [graphColourPalette.article[1]],
		chart: <LineGraph/>,
		disabled: false
	},
]