import React, {useEffect, useState} from "react";
import {keyMetricsApi, kpis} from "./OCADGroupHelper";
import {KPIGroup} from "../KPIGroup/KPIGroup";


export const OCADGroup = ({dateRange, department, departments, setEmpty, setHasError}) => {
	const [dataInfo, setDataInfo] = useState({data: [], loading: true, error:false})
	useEffect(() => keyMetricsApi(dateRange, department, departments, setDataInfo, setHasError, setEmpty), [dateRange, department, departments])
	return (
		<KPIGroup kpis={kpis} dataInfo={dataInfo}/>
	)
}