import {
	GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
  } from "@mui/x-data-grid-pro";
import { CustomToggle } from "../TagStatsTable/DetailPanel/CustomToggle/CustomToggle";
import {getFormattedDateString} from "../../../utils/formatters";

export const embeddedStoriesColumns = [
	{
		field: "publishTime",
		headerName: "Date",
		flex: 2.5,
		editable: false,
		disableColumnMenu: true,
		headerAlign: "left",
		align:"left",
		cellClassName: "date",
		valueGetter: (params) => getFormattedDateString(params.value),
	},
	{
		field: "contentHeadline",
		headerName: "Title",
		flex: 6,
		editable: false,
		disableColumnMenu: true,
		headerAlign: "left",
		align:"left",
	},
	{
		...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
		headerName:"Detail Panel Toggle",
		renderCell: (params) => (
		  <CustomToggle isExpanded={params.value} />
		),
	  },
]