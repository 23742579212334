import apiCall from "../utils/apiCall";
import { ID_TOKEN_STORAGE } from "../assets/styles/variables";

export const verifyUserTable = (updateLastLogin = true) => new Promise(resolve => {
    const idToken = localStorage.getItem(ID_TOKEN_STORAGE);
    if (!idToken) return false;

    const query = `
        query user($updateLastLogin: Boolean!) {
            user(updateLastLogin: $updateLastLogin) {
                isApproved
            }
        }`;

    const variables = {
        updateLastLogin
    };

    return apiCall(query, variables).then(response => {
        const { user } = response;
        return resolve(user);
    })
        .catch(error => {
            console.log({ verifyUserTable: error });
            return resolve(false);
        });
});

