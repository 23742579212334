import apiCall from "../../../../../utils/apiCall";
import {getFormattedDate} from "../../../../../utils/formatters";


export const emptyMessage = "Content tag data currently unavailable";

export const tagCardApi = (contentId, dateRange, setDataInfo, setHasError) => {
	setDataInfo((prevState) => ({...prevState, loading: true, error:false}))

	const startDate = getFormattedDate(dateRange[0])
	const endDate = getFormattedDate(dateRange[1])
	const query = `
                query contentDetail($startDate: String!, $endDate: String!, $contentId: String!, ) {
                    contentDetail(startDate: $startDate, endDate: $endDate, contentId: $contentId) {
                        categories
                    }
                }
            `;

	const queryVariable = {startDate: startDate, endDate: endDate, contentId: contentId};

	apiCall(query, queryVariable)
		.then(response => {
			let data = response.contentDetail.categories
			if (data == null || data === -1) {
				throw new Error("No content categories available ")
			}
			setDataInfo({data: data, loading: false, error: false})
		})
		.catch(error => {
			setDataInfo((prevState) => ({...prevState, loading: false, error:true}))
			setHasError(true)
			console.log({contentTagError: error});
		});
}