import React from "react"
import {Route} from "react-router-dom"
import {useAuth} from "./firebase/context";

import {ID_TOKEN_STORAGE} from "./assets/styles/variables";
import {LogoProgress} from "./components/Loading/Progress/LogoProgress/LogoProgress";
import {Login} from "./components/Pages/Login/Login";

export const PrivateRoute = ({path, children}) => {
    const {loading, isAuth} = useAuth()
    const isValid = localStorage.getItem(ID_TOKEN_STORAGE)

    if (loading && !isAuth) {
        return <LogoProgress overlay={true}/>
    }

    return (
        <Route path={path}>
            {!loading && isValid ? children : <Login/>}
        </Route>
    )
}