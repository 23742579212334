import React from "react";
import {Grid, Typography, Divider, Box} from "@mui/material";
import {mediaKPIGroupStyle} from "./MediaKPIGroupStyle";
import {kpis, primaryKPIs} from "./MediaKPIGroupHelper";
import CountUp from "react-countup";
import PropTypes from "prop-types";
export const MediaKPIGroup = ({data}) => {
	return (
		<Grid container item direction={"row"} justifyContent={"start"} wrap={"nowrap"} spacing={1}>
			{kpis.map((kpi, index) => {
				if (primaryKPIs.has(kpi.accessor)) {
					return (
                    <Grid container item direction={"column"} justifyContent={"center"} sx={mediaKPIGroupStyle.grid}>
                        <Grid item>
                            <Typography sx={mediaKPIGroupStyle.title}>
                                    {kpi.title}
                            </Typography>
                        </Grid>
                        <Divider sx={mediaKPIGroupStyle.divider}/>
                        <Grid item sx={mediaKPIGroupStyle.primaryValue}>
                            <CountUp end={data[kpi.accessor]}
                                    duration={2}
                                    separator={","}
                                    decimals={0}
                                    suffix={"%"}
                            />
                        </Grid>
                    </Grid>)
				}
				return (
                <Grid item>
                    <Grid container item direction={"column"} justifyContent={"center"} sx={mediaKPIGroupStyle.secondaryGrid} spacing={1}>
                        <Grid item>
                            <Typography sx={mediaKPIGroupStyle.secondaryTitle}>
                                {kpi.title}
                            </Typography>
                        </Grid>
                        <Box sx={mediaKPIGroupStyle.spacer}/>
                        <Grid item sx={mediaKPIGroupStyle.secondaryValue}>
                            <CountUp end={data[kpi.accessor]}
                                    duration={2}
                                    separator={","}
                                    decimals={0}
                            />
                        </Grid>
                    </Grid>
				</Grid>)
			 }
            
            )}
		</Grid>
	)
}

MediaKPIGroup.propTypes = {
	data: PropTypes.shape({
		landing: PropTypes.shape({
			watchedStreams: PropTypes.number.isRequired,
			streams: PropTypes.number.isRequired,
			watched: PropTypes.number.isRequired,
		}).isRequired,
		player: PropTypes.shape({
			watchedStreams: PropTypes.number.isRequired,
			streams: PropTypes.number.isRequired,
			watched: PropTypes.number.isRequired,
		}).isRequired,
	}).isRequired,

};