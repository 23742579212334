import { GlobalStyles as MuiGlobalStyles } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import { BG_COLOR, TEXT_COLOR } from "./themes";
export const GlobalStyles = () => (
  <>
    <CssBaseline />
    <MuiGlobalStyles
      styles={{
        '@import': [
          "url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap')",
        ],
        'html, body': {
          margin: 0,
          padding: 0,
          fontFamily: "'Open Sans', sans-serif",
          fontSize: "12pt",
          backgroundColor: BG_COLOR,
          color: TEXT_COLOR,
        },
          'h1, h2, h3, h4, h5, h6, strong': {
          fontWeight: 700,
        },
        '*, *::before, *::after': {
          boxSizing: 'border-box',
        },
      }}
    />
  </>
);

