export const videoPerformanceCardStyle = {
	title: {typography: "text.h5"},
	subtitle: {
        typography: "text.subtitle2",
        fontWeight: 600,
        paddingLeft:0
    },
    icon:{color:"grey.500"},
    paperGrid: {
        minHeight:"275px"
    }
}